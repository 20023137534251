const API_URL = `${process.env.REACT_APP_API_URL}`;
const apiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

export const UrlConstant = {
  common: {
    getUserTypeList: `${API_URL}/v1/Common/GetUserTypeDropDownList`,
    getCountryList: `${API_URL}/v1/Common/GetCountryDropDownList`,
    getMasterBusinessList: `${API_URL}/v1/Common/GetMasterBusinessesDropDownList`,
    getCountryStateList: `${API_URL}/v1/Common/GetCountryStateDropDownList`,
    getServiceCategoryList: `${API_URL}/v1/Common/GetServiceCategoryDropDownList`,
    getPartnerList: `${API_URL}/v1/Common/GetPartnerDropDownList`,
    getSupportDataByTypeDropDownList: `${API_URL}/v1/Common/GetSupportDataByTypeDropDownList`,
    getOnboardingUserTypePagesDropDownList: `${API_URL}/v1/Common/GetOnboardingUserTypePagesDropDownList`,
    getServiceListByBusinessId: `${API_URL}/v1/Common/GetServiceListByBusinessId`,
    getUserAccessibilityDetail: `${API_URL}/v1/Common/GetUserAccessibilityDetail`,
    getBusinessDropDownList: `${API_URL}/v1/Common/GetBusinesses`,
    getBusinessForFacilityDropDownList: `${API_URL}/v1/Common/GetBusinessesForFacility`,
    getServiceCategoryDropDownList: `${API_URL}/v1/Common/GetServiceCategoryDropDownList`,
    getServicePacksList: `${API_URL}/v1/Common/GetServicePacks`,
    getServicePackForms: `${API_URL}/v1/Common/GetServicePackForms`,
    getServiceDropDownList: `${API_URL}/v1/Common/ServiceDropDownList`,
    getLicenseValidInStatesDropDownList: `${API_URL}/v1/Common/GetLicenseValidInStatesDropDownList`,
    getFacilitiesByUserDetailId: `${API_URL}/v1/Common/GetFacilitiesByUserDetailId`,
    getCurrencyDropDownList: `${API_URL}/v1/Common/GetCurrencyDropDownList`,
    getTelemedicineDescription: `${API_URL}/v1/Common/GetTelemedicineDescription`,
    saveTelemedicineDescription: `${API_URL}/v1/Common/SaveTelemedicineDescription`,
    getServiceProviderList: `${API_URL}/v1/Common/GetServiceProviderListByBusinessId`,
    getCouponListByBusinesses: `${API_URL}/v1/Common/GetCouponListByBusinesses`,
    getServiceList: `${API_URL}/v1/Common/GetServiceListByBusinesses`,
    getFacilityList: `${API_URL}/v1/Common/GetFacilityListByBusinesses`,
    getWhiteLabelCurrency: `${API_URL}/v1/LicenseApp/GetWhiteLabelCurrency`,
    getWhiteLabelFeatureList: `${API_URL}/v1/LicenseApp/GetWhiteLabelFeatureList`,
    getTrueWhiteLabelFeatureList: `${API_URL}/v1/LicenseApp/GetTrueWhiteLabelFeatureList`,
    getWhiteLabelFeatureValueList: `${API_URL}/v1/LicenseApp/GetWhiteLabelFeatureValueList`,
    getTimeZoneList: `${API_URL}/v1/Common/GetTimeZoneList`,
    getServiceProvidersList: `${API_URL}/v1/Common/ServiceProviderDropdownList`,
    getClinicStateList: `${API_URL}/v1/Common/GetClinicStateDropdownList`,
    getBusinessesWithBookingType: `${API_URL}/v1/Common/GetBusinessesWithBookingType`,
    getClientList: `${API_URL}/v1/Common/GetClientList`,
    getHCPList: `${API_URL}/v1/Common/GetHCPListByBusinessId`,
    getTimeZoneInfoList: `${API_URL}/v1/Common/GetTimeZoneInfoList`,
  },
  state: {
    stateList: `${API_URL}/v1/State/GetStateList`,
    getStateById: `${API_URL}/v1/State/GetStateById`,
    createState: `${API_URL}/v1/State/AddState`,
    updateSate: `${API_URL}/v1/State/UpdateState`,
    deleteState: `${API_URL}/v1/State/DeleteState`,
  },
  country: {
    countryList: `${API_URL}/v1/Country/GetCountryList`,
    getCountryById: `${API_URL}/v1/Country/GetCountryById`,
    createCountry: `${API_URL}/v1/Country/AddCountry`,
    updateCountry: `${API_URL}/v1/Country/UpdateCountry`,
    deleteCountry: `${API_URL}/v1/Country/DeleteCountry`,
    countryCurrencyList: `${API_URL}/v1/Country/GetCountryCurrencyList`,
  },

  serviceCategory: {
    serviceCategoryList: `${API_URL}/v1/ServiceCategory/GetServiceCategory`,
    getServiceCategoryById: `${API_URL}/v1/ServiceCategory/GetServiceCategoryById`,
    createServiceCategory: `${API_URL}/v1/ServiceCategory/AddServiceCategory`,
    updateServiceCategory: `${API_URL}/v1/ServiceCategory/UpdateServiceCategory`,
    deleteServiceCategory: `${API_URL}/v1/ServiceCategory/DeleteServiceCategory`,
  },
  serviceGroup: {
    serviceGroupList: `${API_URL}/v1/ServiceGroup/GetServiceGroup`,
    getServiceGroupById: `${API_URL}/v1/ServiceGroup/GetServiceGroupById`,
    saveServiceGroup: `${API_URL}/v1/ServiceGroup/SaveServiceGroup`,
    deleteServiceGroup: `${API_URL}/v1/ServiceGroup/DeleteServiceGroup`,
  },
  notes: {
    getNotes: `${API_URL}/v1/Note/GetNotes`,
    addNote: `${API_URL}/v1/Note/AddNote`,
  },
  onboardingFlow: {
    getOnboardingFlows: `${API_URL}/v1/OnboardingFlow/GetOnboardingFlows`,
    addOnboardingFlow: `${API_URL}/v1/OnboardingFlow/AddOnboardingFlow`,
    getOnboardFlowById: `${API_URL}/v1/OnboardingFlow/GetOnboardFlowById`,
    updateOnboardingFlow: `${API_URL}/v1/OnboardingFlow/UpdateOnboardingFlow`,
    getOnboardingPreBuiltScreens: `${API_URL}/v1/OnboardingPreBuiltScreen/GetOnboardingPreBuiltScreens`,
    addOnboardingFormLinking: `${API_URL}/v1/OnboardingFormLinking/AddOnboardingFormLinking`,
    updateOnboardingFormLinking: `${API_URL}/v1/OnboardingFormLinking/UpdateOnboardingFormLinking`,
    getOnboardFormLinkingById: `${API_URL}/v1/OnboardingFormLinking/GetOnboardFormLinkingById`,
    getOnboardingDocumentList: `${API_URL}/v1/OnboardingApp/GetOnboardingDocumentList`,
    cloneOnboardingFlow: `${API_URL}/v1/OnboardingFlow/CloneOnboardingFlow`,
    deleteOnboardingFlow: `${API_URL}/v1/OnboardingFlow/DeleteOnboardingFlow`,
  },
  onboardingStep: {
    addOnboardingStep: `${API_URL}/v1/OnboardingStep/AddOnboardingStep`,
    updateOnboardingStep: `${API_URL}/v1/OnboardingStep/UpdateOnboardingStep`,
    getOnboardingStepById: `${API_URL}/v1/OnboardingStep/GetOnboardingStepById`,
    getOnboardingStepFormControlList: `${API_URL}/v1/OnboardingStep/GetOnboardingStepFormControlList`,
    getOnboardingStepFormCategoryById: `${API_URL}/v1/OnboardingStep/GetOnboardingStepFormCategoryById`,
    addOnboardingStepFormCategory: `${API_URL}/v1/OnboardingStep/AddOnboardingStepFormCategory`,
    updateOnboardingStepFormCategory: `${API_URL}/v1/OnboardingStep/UpdateOnboardingStepFormCategory`,
    getOnboardingAppStepsList: `${API_URL}/v1/OnboardingApp/GetOnboardingAppStepsList`,
    completeOnboardingFormStep: `${API_URL}/v1/OnboardingApp/CompleteOnboardingFormStep`,
    completeExternalLinkStep: `${API_URL}/v1/OnboardingApp/CompleteExternalLinkStep`,
    markUserOnboardingStepStatusAsPending: `${API_URL}/v1/OnboardingApp/MarkUserOnboardingStepStatusAsPending`,
  },
  onboardingStepDetail: {
    addOnboardingStepDetail: `${API_URL}/v1/OnboardingStepDetail/AddOnboardingStepDetail`,
    updateOnboardingStepDetail: `${API_URL}/v1/OnboardingStepDetail/UpdateOnboardingStepDetail`,
    getOnboardingStepDetailById: `${API_URL}/v1/OnboardingStepDetail/GetOnboardingStepDetailById`,
  },
  onboardingStepReport: {
    userOnboardingStepWiseReport: `${API_URL}/v1/OnboardingStep/GetUserOnboardingStepWiseReport`,
    userOnboardingStepWiseReportExportToCsv: `${API_URL}/v1/OnboardingStep/OnboardingStepWiseReportExportToCsv`
  },
  business: {
    getBusinessById: `${API_URL}/v1/Business/GetBusinessById`,
    getBusinessAdminList: `${API_URL}/v1/Business/GetBusinessAdminList`,
    addBusiness: `${API_URL}/v1/Business/AddBusiness`,
    getBusiness: `${API_URL}/v1/Business/GetBusiness`,
    deleteBusiness: `${API_URL}/v1/Business/DeleteBusiness`,
    updateBusiness: `${API_URL}/v1/Business/UpdateBusiness`,
    businessExportToCsv: `${API_URL}/v1/Business/BusinessExportToCsv`,
    saveInsuranceBillingDetails: `${API_URL}/v1/Business/SaveInsuranceBillingDetails`,
    getInsuranceBillingDetails: `${API_URL}/v1/Business/GetInsuranceBillingDetails`,
    copyBusiness: `${API_URL}/v1/Business/CopyBusiness`,
    getSubscriptionPlansForBusinessSP: `${API_URL}/v1/Business/GetSubscriptionPlansForBusinessSP`,
    getBookingTypeWiseBusinessCommission: `${API_URL}/v1/Business/GetBookingTypeWiseBusinessCommission`,
    saveBookingTypeWiseBusinessCommission: `${API_URL}/v1/Business/SaveBookingTypeWiseBusinessCommission`,
  },
  parentBusiness: {
    getParentBusiness: `${API_URL}/v1/Business/GetParentBusiness`,
    addParentBusiness: `${API_URL}/v1/Business/AddParentBusiness`,
    updateParentBusiness: `${API_URL}/v1/Business/UpdateParentBusiness`,
    deleteParentBusiness: `${API_URL}/v1/Business/DeleteParentBusiness`,
  },
  subscription: {
    getSubscriptionList: `${API_URL}/v1/Subscription/GetSubscriptionList`,
    getSubscriptionById: `${API_URL}/v1/Subscription/GetSubscriptionById`,
    createSubscription: `${API_URL}/v1/Subscription/AddSubscription`,
    updateSubscription: `${API_URL}/v1/Subscription/UpdateSubscription`,
    deleteSubscription: `${API_URL}/v1/Subscription/DeleteSubscription`,
    copySubscription: `${API_URL}/v1/Subscription/CopySubscription`,
  },
  subscriptionPlan: {
    getSubscriptionPlanById: `${API_URL}/v1/SubscriptionPlan/GetSubscriptionPlanById`,
    addSubscriptionPlan: `${API_URL}/v1/SubscriptionPlan/AddSubscriptionPlan`,
    updateSubscriptionPlan: `${API_URL}/v1/SubscriptionPlan/UpdateSubscriptionPlan`,
  },
  userManagement: {
    getUserTypeList: `${API_URL}/v1/UserManagement/GetUserTypeList`,
    userTypeListForTypeWiseLogin: `${API_URL}/v1/UserManagement/UserTypeListForTypeWiseLogin`,
    getUserRolesByEmailId: `${API_URL}/v1/UserManagement/GetUserRolesByEmailId`,
  },
  featurePermission: {
    getFeaturePermissionList: `${API_URL}/v1/FeaturePermission/GetFeaturePermissionList`,
    updateFeaturePermissionList: `${API_URL}/v1/FeaturePermission/UpdateFeaturePermissions`,
    getFeaturePermissionValueList: `${API_URL}/v1/FeaturePermission/GetFeaturePermissionValueList`,
    getMultiFeaturePermissionValueList: `${API_URL}/v1/FeaturePermission/GetMultiFeaturePermissionValueList`,
  },
  media: {
    getMedialList: `${API_URL}/v1/Media/GetMediaList`,
    deleteMedia: `${API_URL}/v1/Media/DeleteMedia`,
    uploadMedia: `${API_URL}/v1/Media/UploadMedia`,
    uploadMultipleMedia: `${API_URL}/v1/Media/UploadMultipleMedia`,
  },
  facility: {
    addFacility: `${API_URL}/v1/Facility/AddFacility`,
    updateFacility: `${API_URL}/v1/Facility/UpdateFacility`,
    getFacilityList: `${API_URL}/v1/Facility/GetFacilities`,
    deleteFacility: `${API_URL}/v1/Facility/DeleteFacility`,
    getFacilityById: `${API_URL}/v1/Facility/GetFacilityById`,
  },
  service: {
    createService: `${API_URL}/v1/Service/AddService`,
    getServices: `${API_URL}/v1/Service/GetServices`,
    deleteService: `${API_URL}/v1/Service/DeleteService`,
    copyService: `${API_URL}/v1/Service/CloneService`,
    getServiceById: `${API_URL}/v1/Service/GetServiceById`,
    updateService: `${API_URL}/v1/Service/UpdateService`,
    getServiceIngredient: `${API_URL}/v1/Service/GetServiceIngredient`,
    updateIngredient: `${API_URL}/v1/Service/UpdateIngredient`,
    updateServiceInstruction: `${API_URL}/v1/Service/UpdateServiceInstruction`,
    getServiceAddOnOrderList: `${API_URL}/v1/Service/GetServiceAddOnOrderList`,
    updateServiceAddOnOrder: `${API_URL}/v1/Service/UpdateServiceAddOnOrder`,
    updateServiceInventory: `${API_URL}/v1/Service/UpdateServiceInventory`,
    getServiceInventory: `${API_URL}/v1/Service/GetServiceInventory`,
  },
  serviceProvider: {
    addServiceProvider: `${API_URL}/v1/ServiceProvider/AddServiceProvider`,
    getServiceProviderById: `${API_URL}/v1/ServiceProvider/GetServiceProviderById`,
    updateServiceProvider: `${API_URL}/v1/ServiceProvider/UpdateServiceProvider`,
    getServiceProviderList: `${API_URL}/v1/ServiceProvider/GetServiceProviderList`,
    updateServiceProvierStatus: `${API_URL}/v1/ServiceProvider/UpdateServiceProvierStatus`,
    serviceProviderExportToCsv: `${API_URL}/v1/ServiceProvider/ServiceProviderExportToCsv`,
    addUpdateAdditionalBusiness: `${API_URL}/v1/ServiceProvider/AddUpdateAdditionalBusiness`,
    getAdditionalBusinessList: `${API_URL}/v1/ServiceProvider/AdditionalBusinessList`,
    getServiceList: `${API_URL}/v1/ServiceProvider/GetServiceList`,
    updateService: `${API_URL}/v1/ServiceProvider/UpdateService`,
    getAreaOfOperationById: `${API_URL}/v1/ServiceProvider/GetAreaOfOperationById`,
    addAreaOfOperation: `${API_URL}/v1/ServiceProvider/AddAreaOfOperation`,
    updateAreaOfOperation: `${API_URL}/v1/ServiceProvider/UpdateAreaOfOperation`,
    updateServiceProviderSettings: `${API_URL}/v1/ServiceProvider/UpdateServiceProviderSettings`,
    getServiceProviderSettingsById: `${API_URL}/v1/ServiceProvider/GetServiceProviderSettingsById`,
    getReviewList: `${API_URL}/v1/UserReview/GetReviewList`,
    getVacationList: `${API_URL}/v1/ServiceProvider/GetUserVacationList`,
    getPaymentMethodList: `${API_URL}/v1/ServiceProvider/GetPaymentMethodList`,
    addAvailableHourDetail: `${API_URL}/v1/ServiceProvider/AddAvailableHourDetail`,
    getAvailableHourDetailById: `${API_URL}/v1/ServiceProvider/GetAvailableHourDetailById`,
    updateAvailableHourDetail: `${API_URL}/v1/ServiceProvider/UpdateAvailableHourDetail`,
    getAvailableHourList: `${API_URL}/v1/ServiceProvider/GetAvailableHourList`,
    updateHCPAdvanceBookingTime: `${API_URL}/v1/ServiceProvider/UpdateHCPAdvanceBookingTime`,
    getHCPAdvanceBookingTime: `${API_URL}/v1/ServiceProvider/GetHCPAdvanceBookingTime`,
    updateUserCommissionSetting: `${API_URL}/v1/ServiceProvider/UpdateUserCommissionSetting`,
    getUserCommissionList: `${API_URL}/v1/ServiceProvider/GetUserCommissionList`,
  },
  coupon: {
    addCoupon: `${API_URL}/v1/Coupon/AddCoupon`,
    updateCoupon: `${API_URL}/v1/Coupon/UpdateCoupon`,
    getCouponById: `${API_URL}/v1/Coupon/GetCouponById`,
    getCoupons: `${API_URL}/v1/Coupon/GetCoupons`,
    deleteCoupon: `${API_URL}/v1/Coupon/DeleteCoupon`,
    couponUsageList: `${API_URL}/v1/Coupon/GetCouponUsageList`,
    couponUsageExportToCsv: `${API_URL}/v1/Coupon/CouponUsageExportToCsv`,
    getAllCouponUsageList: `${API_URL}/v1/Coupon/GetAllCouponUsageList`,
    allCouponUsagesExportToCsv: `${API_URL}/v1/Coupon/AllCouponUsagesExportToCsv`,
    serviceBookingDiscountCalculations: `${API_URL}/v1/Widget/ServiceBookingDiscountCalculations`
  },
  user: {
    addUser: `${API_URL}/v1/UserManagement/AddUser`,
    updateUser: `${API_URL}/v1/UserManagement/UpdateUser`,
    getUserById: `${API_URL}/v1/UserManagement/GetUserById`,
    deleteUser: `${API_URL}/v1/UserManagement/DeleteUser`,
    getUserList: `${API_URL}/v1/UserManagement/UserList`,
    usersExportToCsv: `${API_URL}/v1/UserManagement/UsersExportToCsv`,
    clientRegistration: `${API_URL}/v1/UserManagement/ClientRegistration`,
    sendMail: `${API_URL}/v1/UserManagement/Sendmail`,
    getClientList: `${API_URL}/v1/UserManagement/ClientListForVsdh`,
    getPrimaryInfoUserList: `${API_URL}/v1/UserManagement/GetPrimaryInfoUserList`,
  },
  medicalConsent: {
    saveMedicalConsent: `${API_URL}/v1/MedicalConsent/SaveMedicalConsent`,
    getMedicalConsentDetail: `${API_URL}/v1/MedicalConsent/GetMedicalConsentDetail`,
  },
  clientBookingChoice: {
    getClientBookingChoiceList: `${API_URL}/v1/BookingChoiceScreenSetup/GetBookingChoiceList`,
    saveBookingChoice: `${API_URL}/v1/BookingChoiceScreenSetup/SaveClientBookingChoice`,
  },
  membershipPlan: {
    getMembershipPlanList: `${API_URL}/v1/MembershipPlan/GetMembershipPlanList`,
    deleteMembershipPlan: `${API_URL}/v1/MembershipPlan/DeleteMembershipPlan`,
    getMembershipPlanById: `${API_URL}/v1/MembershipPlan/GetMembershipPlanById`,
    addMembershipPlan: `${API_URL}/v1/MembershipPlan/AddMembershipPlan`,
    updateMembershipPlan: `${API_URL}/v1/MembershipPlan/UpdateMembershipPlan`,
    getMembershipPlanMembersList: `${API_URL}/v1/UserMembership/GetMembershipPlanMembersList`,
    getMembershipPlanMemberDetails: `${API_URL}/v1/UserMembership/GetMembershipPlanMemberDetails`,
    getMembershipPlamMemberUsage: `${API_URL}/v1/UserMembership/GetMembershipUsageByUserMembershipId`,
    cancelMembership: `${API_URL}/v1/UserMembership/CancelUserMembership`,
    getMembershipPlanDetail: `${API_URL}/v1/UserMembership/GetMembershipPlanDetail`,
    adjustMembership: `${API_URL}/v1/UserMembership/AdjustMembership`,
  },
  insurance: {
    getInsuranceSettingDetail: `${API_URL}/v1/Insurance/GetInsuranceSettingDetail`,
    saveInsuranceSettingDetail: `${API_URL}/v1/Insurance/SaveInsuranceSettingDetail`,
    getInsuranceSalesReportList: `${API_URL}/v1/Insurance/GetInsuranceSalesReportList`,
    insuranceSalesReportExportToCsv: `${API_URL}/v1/Insurance/InsuranceSalesReportExportToCsv`,
    getInsuranceSalesReportOrderDetail: `${API_URL}/v1/Insurance/GetInsuranceSalesReportOrderDetails`,
    updateInsuranceStatus: `${API_URL}/v1/Insurance/UpdateInsuranceStatus`,
    getVitalsDetails: `${API_URL}/v1/InSessionBooking/GetPastVitalDetails`,
    getInsurancePolicyDetail: `${API_URL}/v1/Insurance/GetInsurancePolicyDetails`,
    medicalConsentDetail: `${API_URL}/v1/UserConsent/GetBookingUserConsentDetails`,
  },
  complimentaryIV: {
    addComplimentaryIVInfo: `${API_URL}/v1/ComplimentaryIV/AddComplimentaryIVInfo`,
    updateComplimentaryIVInfo: `${API_URL}/v1/ComplimentaryIV/UpdateComplimentaryIVInfo`,
    getComplimentaryIVInfoById: `${API_URL}/v1/ComplimentaryIV/GetComplimentaryIVInfoById`,
    getComplimentaryIVList: `${API_URL}/v1/ComplimentaryIV/GetComplimentaryIVList`,
    deleteComplimentaryIV: `${API_URL}/v1/ComplimentaryIV/DeleteComplimentaryIV`,
    checkComplimentaryInBusiness: `${API_URL}/v1/ComplimentaryIV/CheckBusinessExistenceInComplimentaryIV`,
    getComplimentaryIVTopupList: `${API_URL}/v1/ComplimentaryIV/GetComplimentaryTopupList`,
    addComplimentaryIVTopup: `${API_URL}/v1/ComplimentaryIV/AddComplimentaryIVTopup`,
    getComplimentaryIVUsageList: `${API_URL}/v1/ComplimentaryIV/GetComplimentaryIVUsageList`,
  },
  lifetimeRewardClub: {
    addRewardClub: `${API_URL}/v1/RewardClub/AddRewardClub`,
    getRewardClubById: `${API_URL}/v1/RewardClub/GetRewardClubById`,
    updateRewardClub: `${API_URL}/v1/RewardClub/UpdateRewardClub`,
    checkBusinessExistenceInRewardClub: `${API_URL}/v1/RewardClub/CheckBusinessExistenceInRewardClub`,
    getLifetimeRewardClubList: `${API_URL}/v1/RewardClub/GetRewardClubList`,
    deleteRewardClub: `${API_URL}/v1/RewardClub/DeleteRewardClub`,
    getAvailableMemberList: `${API_URL}/v1/RewardClub/GetAvailableMemberList`,
    getRewardClubMemberList: `${API_URL}/v1/RewardClub/GetRewardClubMemberList`,
    addMembersToRewardClub: `${API_URL}/v1/RewardClub/AddMembersToRewardClub`,
    removeMembersFromRewardClub: `${API_URL}/v1/RewardClub/RemoveMembersFromRewardClub`,
    getLifetimeRewardClubUsageList: `${API_URL}/v1/RewardClub/GetRewardClubUsageList`,
  },
  affiliate: {
    getAffiliateList: `${API_URL}/v1/Affiliate/GetAffiliateList`,
    deleteAffiliate: `${API_URL}/v1/Affiliate/DeleteAffiliate`,
    getAffiliateById: `${API_URL}/v1/Affiliate/GetAffiliateById`,
    addAffiliate: `${API_URL}/v1/Affiliate/AddAffiliate`,
    updateAffiliate: `${API_URL}/v1/Affiliate/UpdateAffiliate`,
    getAffiliateCommissionListByType: `${API_URL}/v1/Affiliate/GetAffiliateCommissionListByType`,
    saveAffiliateCommissionByType: `${API_URL}/v1/Affiliate/SaveAffiliateCommissionByType`,
    deleteAffiliateCommissionByType: `${API_URL}/v1/Affiliate/DeleteAffiliateCommissionByType`,
    getAffiliateBookingDetails: `${API_URL}/v1/Affiliate/GetAffiliateBookingDetails`,
    affiliateBookingDetailsExportToCsv: `${API_URL}/v1/Affiliate/AffiliateBookingDetailsExportToCsv`,
  },
  servicePack: {
    getServicePackList: `${API_URL}/v1/ServicePack/GetServicePackList`,
  },
  receptionist: {
    addReceptionst: `${API_URL}/v1/Receptionist/AddReceptionist`,
    getByIdReceptionist: `${API_URL}/v1/Receptionist/GetReceptionistById`,
    updatesReceptionist: `${API_URL}/v1/Receptionist/UpdateReceptionist`,
    getReceptionistList: `${API_URL}/v1/Receptionist/GetReceptionistList`,
    deleteReceptionist: `${API_URL}/v1/Receptionist/DeleteReceptionist`,
  },
  dashboard: {
    getDashboardDetails: `${API_URL}/v1/Dashboard/GetBackofficeDashboardDetails`,
    getSPWithNegativeBalanceList: `${API_URL}/v1/Dashboard/GetSPWithNegativeBalance`,
    getBackofficeDNADashboardDetail: `${API_URL}/v1/Dashboard/GetBackofficeDNADashboardDetails`,
    GetBackOfficePharmacyOrdersCount: `${API_URL}/v1/Dashboard/GetBackOfficePharmacyOrdersCount`,
    getBackOfficePreAssessmentList: `${API_URL}/v1/Dashboard/GetBackOfficePreAssessmentList`,
  },
  servicePackForm: {
    getServicePackFormList: `${API_URL}/v1/ServicePackForm/GetServicePackFormList`,
  },
  event: {
    getEventList: `${API_URL}/v1/Event/GetEventList`,
    deleteEvent: `${API_URL}/v1/Event/DeleteEvent`,
    getEventById: `${API_URL}/v1/Event/GetEventById`,
    addEvent: `${API_URL}/v1/Event/AddEvent`,
    updateEvent: `${API_URL}/v1/Event/UpdateEvent`,
  },
  homeScreenSetup: {
    getHomeScreenSetupList: `${API_URL}/v1/HomeScreenSetup/GetHomeScreenSetup`,
    saveHomeScreenSetup: `${API_URL}/v1/HomeScreenSetup/SaveHomeScreenSetup`,
    getHomeScreenDiscountSetup: `${API_URL}/v1/HomeScreenSetup/GetHomeScreenDiscountSetup`,
    saveHomeScreenDiscountSetup: `${API_URL}/v1/HomeScreenSetup/SaveHomeScreenDiscountSetup`,
    getHomeScreenSpotlightSetup: `${API_URL}/v1/HomeScreenSetup/GetHomeScreenSpotlightSetup`,
    saveHomeScreenSpotlightSetup: `${API_URL}/v1/HomeScreenSetup/SaveHomeScreenSpotlightSetup`,
  },
  location: {
    getLocationList: `${API_URL}/v1/Location/GetLocationList`,
    deleteLocation: `${API_URL}/v1/Location/DeleteLocation`,
    getLocationById: `${API_URL}/v1/Location/GetLocationById`,
    addLocation: `${API_URL}/v1/Location/AddLocation`,
    updateLocation: `${API_URL}/v1/Location/UpdateLocation`,
    getLocationAddressByLatLong: (latitude, longitude) =>
      encodeURI(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=${apiKey}`
      )
  },
  checklist: {
    addChecklist: `${API_URL}/v1/ConsentTreatment/AddChecklist`,
    deleteChecklist: `${API_URL}/v1/ConsentTreatment/DeleteChecklist`,
    updateCheckList: `${API_URL}/v1/ConsentTreatment/UpdateCheckList`,
  },
  giftCard: {
    getGiftCardList: `${API_URL}/v1/GiftCard/GetGiftCardList`,
    getGiftCardById: `${API_URL}/v1/GiftCard/GetGiftCardById`,
    getGiftCardUsageList: `${API_URL}/v1/GiftCard/GetGiftCardUsageList`,
  },
  spotlight: {
    getSpotlightList: `${API_URL}/v1/Spotlight/GetSpotlightList`,
    deleteSpotlight: `${API_URL}/v1/Spotlight/DeleteSpotlight`,
    addSpotlight: `${API_URL}/v1/Spotlight/AddSpotlight`,
    getSpotlightById: `${API_URL}/v1/Spotlight/GetSpotlightById`,
    updateSpotlight: `${API_URL}/v1/Spotlight/UpdateSpotlight`,
  },
  news: {
    getNewsList: `${API_URL}/v1/News/GetNewsList`,
    deleteNews: `${API_URL}/v1/News/DeleteNews`,
    addNews: `${API_URL}/v1/News/AddNews`,
    getNewsById: `${API_URL}/v1/News/GetNewsById`,
    updateNews: `${API_URL}/v1/News/UpdateNews`,
  },
  condition: {
    getConditionList: `${API_URL}/v1/MedicalConsultConfiguration/GetConditionList`,
    getConditionById: `${API_URL}/v1/MedicalConsultConfiguration/GetMedicalConsultConditionById`,
    addCondition: `${API_URL}/v1/MedicalConsultConfiguration/AddMedicalConsultCondition`,
    updateCondition: `${API_URL}/v1/MedicalConsultConfiguration/UpdateMedicalConsultCondition`,
  },
  medicalConsultSetting: {
    getMedicalConsultSetting: `${API_URL}/v1/MedicalConsultConfiguration/GetMedicalConsultSetting`,
    addMedicalConsultSetting: `${API_URL}/v1/MedicalConsultConfiguration/AddMedicalConsultSetting`,
  },
  serviceSalesReport: {
    geGetServiceSalesReportList: `${API_URL}/v1/ServiceSalesReport/GetServiceSalesReportList`,
  },
  tipsGratuity: {
    getTipsGratuityList: `${API_URL}/v1/TipsGratuity/GetTipsGratuityList`,
    tipsGratuityExportToCsv: `${API_URL}/v1/TipsGratuity/TipsGratuityExportToCsv`,
  },
  receipt: {
    getReceiptList: `${API_URL}/v1/ReceiptReport/GetReceiptList`,
  },
  salesReport: {
    getSalesReportList: `${API_URL}/v1/SalesReport/GetSalesReportList`,
    getPSFSalesReportList: `${API_URL}/v1/SalesReport/GetPSFSalesReportList`,
    salesReportExportToCsv: `${API_URL}/v1/SalesReport/SalesReportExportToCsv`,
    psfSalesReportExportToCsv: `${API_URL}/v1/SalesReport/PSFSalesReportExportToCsv`,
    updateOrderMarkASTest: `${API_URL}/v1/SalesReport/UpdateOrderMarkASTest`,
    getCancelChargesForPSFCancelSession: `${API_URL}/v1/BookingCancel/GetCancelChargesForPSFCancelSession`,
    cancelPSFBookingSession: `${API_URL}/v1/BookingCancel/CancelPSFBookingSessionByMAAdminReceptionist`,
    updateBookingStatus: `${API_URL}/v1/BookingFlow/UpdateBookingsStatusByMAAdminReceptionist`
  },
  payout: {
    saveRequestPayout: `${API_URL}/v1/CommissionPayoutTransaction/SaveRequestPayout`,
    getPayoutList: `${API_URL}/v1/CommissionPayoutTransaction/GetPayoutList`,
    payoutExportToCsv: `${API_URL}/v1/CommissionPayoutTransaction/PayoutExportToCsv`,
    deletePayout: `${API_URL}/v1/CommissionPayoutTransaction/DeletePayoutTransaction`,
    getPayoutDetail: `${API_URL}/v1/CommissionPayoutTransaction/GetPayoutDetailsById`,
    updatePayoutStatus: `${API_URL}/v1/CommissionPayoutTransaction/updatePayoutStatus`,
    updatePayoutStatusAsPaid: `${API_URL}/v1/CommissionPayoutTransaction/updatePayoutStatusAsPaid`,
  },
  serilog: {
    getBackOfficeLogs: `${API_URL}/v1/SeriLog/GetAllLog`,
    getMobileLog: `${API_URL}/v1/MobileLogUI/GetAllMobileLog`,
  },
  consultation: {
    getConsultationList: `${API_URL}/v1/ServiceConsult/GetConsultationList`,
    getConsulationDetails: `${API_URL}/v1/ServiceConsult/GetConsultationsById`,
    consultationExportToCsv: `${API_URL}/v1/ServiceConsult/ConsultationExportToCsv`,
    getPastPrescriptionsDetails: `${API_URL}/v1/ServiceConsult/GetPastPrescriptionsDetails`,
    getServiceConsultBookingPrescriptionDetailsById: `${API_URL}/v1/ServiceConsult/GetServiceConsultPrescriptionDetail`,
    getPastSoapNotesDetails: `${API_URL}/v1/ServiceConsult/GetPastSOAPNotesOfClient`,
    getServiceConsultBookingSoapNotesById: `${API_URL}/v1/ServiceConsult/GetSOAPDetailByConsultId`,
    getServiceConsultPharmacyOrderDetail: `${API_URL}/v1/ServiceConsult/GetClientServiceConsultPharmacyOrderDetails`,
    getServiceConsultPharmacyOrderDetailById: `${API_URL}/v1/ServiceConsult/GetClientServiceConsultPharmacyOrder`,
    getServiceConsultCheckList: `${API_URL}/v1/ServiceConsult/GetChecklistByServiceConsult`,
    getServiceConsultHCPListForReschedule: `${API_URL}/v1/ServiceConsult/GetServiceConsultHCPListForReschedule`,
    getHCPTimeSlotsList: `${API_URL}/v1/ServiceConsult/GetHCPTimeSlotsList`,
    rescheduleConsultationByBackofficeHCP: `${API_URL}/v1/ServiceConsult/RescheduleConsultationByBackofficeHCP`,
    upcomingConsultations: `${API_URL}/v1/ServiceConsult/GetUpcomingConsultations`,
  },
  inventory: {
    getInventoryList: `${API_URL}/v1/Inventory/GetInventories`,
    getInventoryDetails: `${API_URL}/v1/Inventory/GetInventoryDetails`,
    addInventory: `${API_URL}/v1/Inventory/AddInventoryDetails`,
    updateInventory: `${API_URL}/v1/Inventory/UpdateInventoryDetails`,
    deleteInventory: `${API_URL}/v1/Inventory/DeleteInventory`,
    getServiceProviderList: `${API_URL}/v1/Inventory/ServiceProviderDetailsByInventory`,
    addInventoryToServiceProvider: `${API_URL}/v1/Inventory/AddedInventoryToServiceProvider`,
    removeInventoryFromServiceProvider: `${API_URL}/v1/Inventory/RemoveInventoryFromServiceProvider`,
    adjustBusinessInventory: `${API_URL}/v1/Inventory/AdjustBusinessInventoryLevel`,
    getInventoryHistory: `${API_URL}/v1/Inventory/GetInventoryHistory`,
    getInventoryItemList: `${API_URL}/v1/Inventory/GetInventoryDropDownList`,
    getInventoryManufacturerList: `${API_URL}/v1/Inventory/GetInventoryManufacturers`,
    getInventorySupplierList: `${API_URL}/v1/Inventory/GetInventorySuppliers`,
    getInventoryBarcodeList: `${API_URL}/v1/Inventory/GetBarcodeList`,
    getInventoryUsageList: `${API_URL}/v1/Inventory/GetInventoryUsageList`,
  },
  booking: {
    getUserBookingDetails: `${API_URL}/v1/BookingFlow/GetUserBookingDetails`,
    userPaymentProfileList: `${API_URL}/v1/PaymentGateway/UserPaymentProfileList`,
    SavePSFMedication: `${API_URL}/v1/PSBookingFlow/SavePSFMedication`,
    PSFReorderSelfMedication: `${API_URL}/v1/PSBookingFlow/ReorderSelfMedication`
  },
  medicalConsult: {
    getUserMedicalConsultRequestDetail: `${API_URL}/v1/MedicalConsult/GetUserMedicalConsultRequestDetail`,
  },
  inventoryOrders: {
    getInventoryOrdersList: `${API_URL}/v1/InventoryOrder/GetInventoryOrders`,
    getInventoryOrderDetails: `${API_URL}/v1/InventoryOrder/GetInventoryOrderDetails`,
    deleteInventoryOrder: `${API_URL}/v1/InventoryOrder/DeleteInventoryOrder`,
    addInventoryOrder: `${API_URL}/v1/InventoryOrder/AddInventoryOrder`,
    updateInventoryOrder: `${API_URL}/v1/InventoryOrder/UpdateInventoryOrder`,
    updateInventoryOrderStatus: `${API_URL}/v1/InventoryOrder/UpdateInventoryOrderItemStatus`,
  },
  payoutAdjustments: {
    getPayoutAdjustmentsList: `${API_URL}/v1/CommissionPayoutAdjustment/GetCommissionPayoutAdjustmentList`,
    getPayoutAdjustmentsDetails: `${API_URL}/v1/CommissionPayoutAdjustment/GetCommissionPayoutAdjustmentDetails`,
    addPayoutAdjustment: `${API_URL}/v1/CommissionPayoutAdjustment/AddCommissionPayoutAdjustment`,
    updatePayoutAdjustment: `${API_URL}/v1/CommissionPayoutAdjustment/UpdateCommissionPayoutAdjustment`,
    deletePayoutAdjustment: `${API_URL}/v1/CommissionPayoutAdjustment/DeleteCommissionPayoutAdjustment`,
    getPayeeList: `${API_URL}/v1/CommissionPayoutAdjustment/GetPayeeList`,
    getPayeeOrderList: `${API_URL}/v1/CommissionPayoutAdjustment/GetPayeeOrderList`,
    multipleCommissionPayoutAdjustment: `${API_URL}/v1/CommissionPayoutAdjustment/MultipleCommissionPayoutAdjustment`,
  },
  commissionBalance: {
    getCommissionBalanceList: `${API_URL}/v1/BookingCommission/GetCommissionBalanceList`,
    getServiceProviderNegativeBalanceList: `${API_URL}/v1/BookingCommission/GetServiceProviderNegativeBalanceList`,
    getBusinessNegativeBalanceList: `${API_URL}/v1/BookingCommission/GetBusinessNegativeBalanceList`,
  },
  paymentConfiguration: {
    getPaymentConfigurationList: `${API_URL}/v1/Payment/GetPaymentConfigurationList`,
    getPaymentConfigurationDetails: `${API_URL}/v1/Payment/GetPaymentConfigurationDetails`,
    addPaymentConfiguration: `${API_URL}/v1/Payment/AddPaymentConfiguration`,
    updatePaymentConfiguration: `${API_URL}/v1/Payment/UpdatePaymentConfiguration`,
    getBusinessPaymentGroupConfigDetail: `${API_URL}/v1/PaymentConfiguration/GetBusinessPaymentGroupConfigDetailList`,
    getPaymentGatewayConfigList: `${API_URL}/v1/PaymentConfiguration/GetPaymentGatewayConfigList`,
    getBusinessPaymentGatewayConfigDetail: `${API_URL}/v1/PaymentConfiguration/GetBusinessPaymentGatewayConfigDetail`,
    updateBusinessPaymentConfiguration: `${API_URL}/v1/PaymentConfiguration/UpdateBusinessPaymentConfiguration`,
    addBusinessPaymentConfiguration: `${API_URL}/v1/PaymentConfiguration/AddBusinessPaymentConfiguration`,
    deleteBusinessPaymentConfiguration: `${API_URL}/v1/PaymentConfiguration/DeleteBusinessPaymentConfiguration`,
    updateBusinessPaymentConfigurationAsDefault: `${API_URL}/v1/PaymentConfiguration/UpdateBusinessPaymentConfigurationAsDefault`,
  },
  training: {
    addTrainingSubjectDetails: `${API_URL}/v1/Training/AddTrainingSubjectDetails`,
    getTrainingSubjectList: `${API_URL}/v1/Training/GetTrainingSubjectList`,
    updateTrainingSubjectDetails: `${API_URL}/v1/Training/UpdateTrainingSubjectDetails`,
    getTrainingSubjectDetails: `${API_URL}/v1/Training/GetTrainingSubjectDetails`,
    deleteTraingSubject: `${API_URL}/v1/Training/DeleteTrainingSubject`,
    addTrainingSubjectTestAndTopicDetails: `${API_URL}/v1/Training/AddTrainingSubjectTestAndTopicDetails`,
    updateTrainingSubjectTestAndTopicDetails: `${API_URL}/v1/Training/UpdateTrainingSubjectTestAndTopicDetails`,
    deleteTrainingSubjectTestAndTopicDetails: `${API_URL}/v1/Training/DeleteTrainingSubjectTestAndTopicDetails`,
    getTrainingSubjectTestAndTopicList: `${API_URL}/v1/Training/GetTrainingSubjectTestAndTopicList`,
    getTrainingDashboardData: `${API_URL}/v1/Training/GetTrainingDashboardDetails`,
    addTrainingTopicStepDetails: `${API_URL}/v1/Training/AddTrainingTopicStepDetails`,
    updateTrainingTopicStepDetails: `${API_URL}/v1/Training/UpdateTrainingTopicStepDetails`,
    getTrainingTopicStepList: `${API_URL}/v1/Training/GetTrainingTopicStepList`,
    deleteTrainingTopicStep: `${API_URL}/v1/Training/DeleteTrainingTopicStep`,
    getTestQuestionAnswerList: `${API_URL}/v1/Training/GetTestQuestionAnswerList`,
    addTestQuestionAnswerList: `${API_URL}/v1/Training/AddTestQuestionAnswerDetails`,
    updateTestQuestionAnswerList: `${API_URL}/v1/Training/UpdateTestQuestionAnswerDetails`,
    deleteTestQuestionDetails: `${API_URL}/v1/Training/DeleteTestQuestionDetails`,
    getTrainingSubjectTestAndTopicDetails: `${API_URL}/v1/Training/GetTrainingSubjectTestAndTopicDetails`,
    cloneTrainingSubjectDetails: `${API_URL}/v1/Training/CloneTrainingSubjectDetails`,
    cloneTrainingSubjectTestAndTopicDetails: `${API_URL}/v1/Training/CloneTrainingSubjectTestAndTopicDetails`,
    cloneTrainingTopicStepDetails: `${API_URL}/v1/Training/CloneTrainingTopicStepDetails`,
    getTrainingUserList: `${API_URL}/v1/Training/GetTrainingUserList`,
    getSubjectList: `${API_URL}/v1/Training/GetSubjectList`,
    getTrainingUserDetails: `${API_URL}/v1/Training/GetTrainingUserDetails`,
    applyTrainingConfigToUsers: `${API_URL}/v1/TrainingApp/ApplyTrainingConfigToUsers`,
  },

  clientTraining: {
    getUserTrainingSubjectList: `${API_URL}/v1/TrainingApp/GetUserTrainingSubjectList`,
    getUserTrainingSubjectTestAndTopicList: `${API_URL}/v1/TrainingApp/GetUserTrainingSubjectTestAndTopicList`,
    getUserTrainingTopicDetailStepList: `${API_URL}/v1/TrainingApp/GetUserTrainingTopicDetailStepList`,
    getUserTrainingTestDetail: `${API_URL}/v1/TrainingApp/GetUserTrainingTestDetail`,
    getUserTrainingTestQuestionList: `${API_URL}/v1/TrainingApp/GetUserTrainingTestQuestionList`,
    submitTestQuestionAnswer: `${API_URL}/v1/TrainingApp/SubmitTestQuestionAnswer`,
    resetUserTrainingQuestionAnswerProgress: `${API_URL}/v1/TrainingApp/ResetUserTrainingQuestionAnswerProgress`,
    updateExistingUserDetailByUserDetailId: `${API_URL}/v1/TrainingApp/UpdateExistingUserDetailByUserDetailId`,
    updateExistingUserDetailByEmailId: `${API_URL}/v1/TrainingApp/UpdateExistingUserDetailByEmailId`,
  },
  refund: {
    getPaymentDetailsForRefundOrder: `${API_URL}/v1/BookingRefund/GetPaymentDetailsForRefundOrder`,
    refundOrderByMasterAdmin: `${API_URL}/v1/BookingRefund/RefundOrderByMasterAdmin`,
    addRefundRequestByAdmin: `${API_URL}/v1/BookingRefund/AddRefundRequestByAdmin`,
    cancelRefundRequest: `${API_URL}/v1/BookingRefund/CancelRefundRequest`,
    acceptRefundRequestByMasterAdmin: `${API_URL}/v1/BookingRefund/AcceptRefundRequestByMasterAdmin`,
    rejectRefundRequestByMasterAdmin: `${API_URL}/v1/BookingRefund/RejectRefundRequestByMasterAdmin`,
  },
  customNotification: {
    getCustomNotificationsList: `${API_URL}/v1/CustomNotification/GetCustomNotificationsList`,
    saveCustomNotification: `${API_URL}/v1/CustomNotification/SaveCustomNotification`,
    getCustomNotificationDetails: `${API_URL}/v1/CustomNotification/GetCustomNotificationDetails`,
  },
  emailTemplate: {
    getTemplateCategoryList: `${API_URL}/v1/Template/GetTemplateCategoryList`,
    getDefaultTagList: `${API_URL}/v1/Template/GetDefaultTagList`,
    getTemplatesByCategoryId: `${API_URL}/v1/Template/GetTemplatesByCategoryId`,
    getTemplateById: `${API_URL}/v1/Template/GetTemplateById`,
    saveTemplate: `${API_URL}/v1/Template/SaveTemplate`
  },
  clubReadyBookingSyncLog: {
    getClubReadyBookingSyncLogList: `${API_URL}/v1/BookingFlow/GetClubReadyBookingSyncLogList`,
    syncingClubReadyBooking: `${API_URL}/v1/Scheduler/SyncingClubReadyBooking`,
    syncingClubReadyBookingNow: `${API_URL}/v1/Scheduler/ClubReadySyncNow`,
    syncClubReadyBusiness: `${API_URL}/v1/Business/SyncClubReadyBusiness`,
    syncClubReadySP: `${API_URL}/v1/ServiceProvider/SyncClubReadySP`,
    syncClubReadyService: `${API_URL}/v1/Service/SyncClubReadyService`,
  },
  SubscriptionReport: {
    GetSubscriptionReport: `${API_URL}/v1/Subscription/GetSubscriptionReport`
  },
  ClientReport: {
    GetClientReport: `${API_URL}/v1/ClientReport/GetClientReportList`,
    GetClientReportById: `${API_URL}/v1/ClientReport/GetClientUserDetailsById`,
    GetUserBookingVitalsDetailsById: `${API_URL}/v1/ClientReport/GetClientUserVitalsDetailsById`,
    GetUserBookingPrescriptionsById: `${API_URL}/v1/ClientReport/GetClientUserPrescriptionsById`,
    GetUserBookingSoapNotesById: `${API_URL}/v1/ClientReport/GetClientUserSoapNotesById`,
    GetClientMedicalHistoryById: `${API_URL}/v1/ClientReport/GetClientUserMedicalHistoryById`,
    GetClientServiceRecievedById: `${API_URL}/v1/ClientReport/GetClientUserServicesTakenById`,
    GetUserBookingPrescriptionDetailsById: `${API_URL}/v1/ClientReport/GetClientUserPrescriptionDetailsById`,
    GetBookingUserConsentDetails: `${API_URL}/v1/UserConsent/GetBookingUserConsentDetails`,
    GetClientWiseMedicalScreeningList: `${API_URL}/v1/MedicalScreening/GetClientWiseMedicalScreeningList`,
    AddGFE: `${API_URL}/v1/MedicalScreening/AddGFE`
  },
  incidentReport: {
    SaveIncidentReportDetail: `${API_URL}/v1/IncidentReport/SaveIncidentReportDetail`,
    GetIncidentReportDetail: `${API_URL}/v1/IncidentReport/GetIncidentReportDetail`,
    GetUserBookingIncidentReportListByBusiness: `${API_URL}/v1/IncidentReport/GetUserBookingIncidentReportListByBusiness`,
  },
  MedicalScreening: {
    GetMedicalScreeningConfigurationDetails: `${API_URL}/v1/MedicalScreening/GetMedicalScreeningConfigurationDetails`,
    SaveMedicalScreeningConfigurationDetails: `${API_URL}/v1/MedicalScreening/SaveMedicalScreeningConfigurationDetails`,
    SaveValidPeriodStateWiseConfigurationDetails: `${API_URL}/v1/MedicalScreening/SaveValidPeriodStateWiseConfigurationDetails`,
    DeleteValidPeriodStateWiseConfigurationDetails: `${API_URL}/v1/MedicalScreening/DeleteValidPeriodStateWiseConfigurationDetails`,
    GetValidPeriodStateWiseConfigurationDetails: `${API_URL}/v1/MedicalScreening/GetValidPeriodStateWiseConfigurationDetails`,
    GetMedicalScreeningDetail: `${API_URL}/v1/MedicalScreening/GetMedicalScreeningDetail`,
    getTelemedicineSectionDetailOnAddService: `${API_URL}/v1/MedicalScreening/GetTelemedicineSectionDetailOnAddService`,
  },
  Widget: {
    GetWidgetConfiguration: `${API_URL}/v1/Widget/GetWidgetConfiguration`,
    WidgetSettingsManager: `${API_URL}/v1/Widget/WidgetSettingsManager`
  },
  DoctorNetwork: {
    getDoctorNetworkServiceProviderList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkServiceProviderList`,
    GetDoctorNetworkHCPTimeSlotsListForPatientSpecific: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPTimeSlotsListForPatientSpecific`,
    doctorNetworkServiceProviderExportToCsv: `${API_URL}/v1/DoctorNetworkMD/DoctorNetworkServiceProviderExportToCsv`,
    getDoctorNetworkConsultationList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkConsultationList`,
    doctorNetworkConsultationExportToCsv: `${API_URL}/v1/DoctorNetworkMD/DoctorNetworkConsultationExportToCsv`,
    getDoctorNetworkHCPList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPList`,
    addLicense: `${API_URL}/v1/DoctorNetworkMD/AddLicense`,
    updateLicense: `${API_URL}/v1/DoctorNetworkMD/UpdateLicense`,
    deleteLicense: `${API_URL}/v1/DoctorNetworkMD/DeleteLicense`,
    getLicenseById: `${API_URL}/v1/DoctorNetworkMD/GetLicenseById`,
    getLicenseList: `${API_URL}/v1/DoctorNetworkMD/GetLicenseList`,
    doctorNetworkServiceProviderExportToCsv: `${API_URL}/v1/DoctorNetworkMD/DoctorNetworkServiceProviderExportToCsv`,
    getDoctorNetworkConsultationList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkConsultationList`,
    doctorNetworkConsultationExportToCsv: `${API_URL}/v1/DoctorNetworkMD/DoctorNetworkConsultationExportToCsv`,
    getDoctorNetworkHCPList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPList`,
    getDoctorNetworkHCPTimeSlotsList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPTimeSlotsList`,
    rescheduleConsultationByDoctorNetworkHCP: `${API_URL}/v1/DoctorNetworkMD/RescheduleConsultationByDoctorNetworkHCP`,
    getDoctorNetworkHCPListForReschedule: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPListForReschedule`,
    getDNABusinesses: `${API_URL}/v1/DoctorNetworkMD/GetDNABusinesses`,
    cancelBookingSessionByDNA: `${API_URL}/v1/DoctorNetworkMD/CancelBookingSessionByDNA`,
    getConsultationWaitingList: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkConsultationWaitingList`,
    getConsultationWaitingDetails: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkConsultationWaiting`,
    getDoctorNetworkHCPListforInstantRescheduling: `${API_URL}/v1/DoctorNetworkMD/GetDoctorNetworkHCPListforInstantRescheduling`,

  },
  MobileMedIVService: {
    getMobileMedIVServicesList: `${API_URL}/v1/MobileMedIVService/GetMobileMedIVServicesList`,
    deleteMobileMedIVService: `${API_URL}/v1/MobileMedIVService/DeleteMobileMedIVService`,
    addMobileMedIVService: `${API_URL}/v1/MobileMedIVService/AddMobileMedIVService`,
    updateMobileMedIVService: `${API_URL}/v1/MobileMedIVService/UpdateMobileMedIVService`,
    getMobileMedIVServiceById: `${API_URL}/v1/MobileMedIVService/GetMobileMedIVServiceById`,
  },
  Pharmacy: {
    getBackOfficePharmacyOrdersDetailsList: `${API_URL}/v1/Dashboard/GetBackOfficePharmacyOrdersDetailsList`,
    UpdateBackOfficePharmacyOrderDetails: `${API_URL}/v1/Dashboard/UpdateBackOfficePharmacyOrderDetails`,
    exportPharmacyOrdersToCsv: `${API_URL}/v1/Dashboard/ExportPharmacyOrdersToCsv`,
  },
  PreAssessment: {
    saveBackOfficePreAssessmentDetails: `${API_URL}/v1/Dashboard/SaveBackOfficePreAssessmentDetails`,
    getBackOfficePreAssessmentDetails: `${API_URL}/v1/Dashboard/GetBackOfficePreAssessmentDetails`,
    getBackOfficePreAssessmentList: `${API_URL}/v1/Dashboard/GetBackOfficePreAssessmentList`,
    updateBackOfficePreAssessmentDetails: `${API_URL}/v1/Dashboard/UpdateBackOfficePreAssessmentDetails`,
  },
  PharmacyLinks: {
    dripbar: "https://thedripbarpharmacy.com/",
	  hydreight: "https://pharmacy-a.hydreight.com/",
  },
  MetabaseReports: {
    generateJWTToken: `${API_URL}/v1/Dashboard/GetReportJwtToken`,
    metabaseSiteUrl : "https://report.hydreight.com/embed/dashboard/{token}#bordered=true&titled=true"
  },
  Support: {
    AddSupportRequest: `${API_URL}/v1/SupportRequest/AddSupportRequest`,
  },
  GetAuditLogs: `${API_URL}/v1/Dashboard/GetAuditLogs`,
};
