import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";

import { useLocalStorage } from "../../components/useLocalStorage";
import { handleFetchGet } from "../../../utils/apiCallsProvider";
import { UrlConstant, DataConstant } from "../../constants";
import { EncryptText, DecryptText } from "../../../utils/helper";
import _ from "lodash";
import LogRocket from 'logrocket';

export const UserTypeSelection = ({
  show,
  onHide,
  isFromSwitchAccount,
  redirectToDashBoard,
}) => {

  const { authToken } = useSelector(
    (state) => ({ authToken: state.auth.authToken }),
    shallowEqual
  );

  const [userTypeList, setUserTypeList] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(0);
  const [userTypeNotFound, setUserTypeNotFound] = useState(false);

  const [userDetail, setUserDetail] = useLocalStorage("userDetail");
  const intl = useIntl();
  const dispatch = useDispatch();

  const getUserTypes = () => {
    handleFetchGet(
      UrlConstant.userManagement.userTypeListForTypeWiseLogin
    ).then((response) => {
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
        setUserTypeList(response.data);
      } else {
        setUserTypeNotFound(true);
      }
    });
  };

  const handleUserTypeChange = () => {
    if (selectedUserType === 0) {
      return NotificationManager.error(
        intl.formatMessage({ id: "userTypeSelection.required.message" }),
        intl.formatMessage({ id: "userType.account.label" }),
        DataConstant.notificationTimeout
      );
    }
    const userDetail = userTypeList.find((i) => {
      return i.userDetailId === selectedUserType;
    });
    setUserDetail(EncryptText(JSON.stringify(userDetail)));
    localStorage.setItem("firstName", userDetail?.firstName)
    localStorage.setItem("lastName", userDetail?.lastName)

    dispatch({
      type: "[Set UserDetail] Action",
      payload: {
        userDetailId: userDetail?.userDetailId,
        userType: userDetail?.userTypeName,
        userTypeId: userDetail?.userTypeId,
        businessName: userDetail?.businessName,
        businessId: userDetail?.businessId,
        whiteLabel: userDetail?.whiteLabel,
        currency: userDetail?.currency,
        sessionId: userDetail?.sessionId,
        allowBusinessToAddMembership: userDetail?.allowBusinessToAddMembership,
        allowCashPayment: userDetail?.allowCashPayment,
        isWithDoctorNetwork: userDetail?.isWithDoctorNetwork,
        firstName: userDetail?.firstName,
        lastName: userDetail?.lastName
      },
    });
    if (process.env.REACT_APP_IS_LOGROCKET_ENABLED === "true") {
      LogRocket.identify(userDetail?.email, {
        userId: userDetail?.userId,
        userDetailId: userDetail?.userDetailId,
        userType: userDetail?.userTypeName,
        isTestAccount: userDetail?.isTestAccount,
        phone: userDetail?.phone
      });
    }
    if (isFromSwitchAccount) {
      redirectToDashBoard();
    }
  };

  const onUserTypeChange = (userDetailId) => {
    setSelectedUserType(userDetailId);
  };

  useEffect(() => {
    getUserTypes();
    if (process.env.REACT_APP_IS_LOGROCKET_ENABLED === "true") {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_PROJECT_ID);
    }
  }, []);

  useEffect(() => {
    if (userDetail) {
      const user = JSON.parse(DecryptText(userDetail));
      setSelectedUserType(user.userDetailId);
    }
  }, [userDetail, show]);

  return (
    <>
      <Modal show={show && !_.isEmpty(authToken)} onHide={onHide} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="userTypeSelection.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!userTypeNotFound &&
            userTypeList.map((type) => (
              <div key={type.userDetailId} className="pb-5">
                <label>{type.businessName || type.userTypeName}</label>
                <Form.Check
                  type="radio"
                  id={type.userDetailId}
                  name="userType"
                  checked={selectedUserType === type.userDetailId}
                  label={type.userTypeName}
                  onChange={() => {
                    onUserTypeChange(type.userDetailId);
                  }}
                />
              </div>
            ))}
          {userTypeNotFound && (
            <FormattedMessage id="userTypeSelection.notFound.message" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage id="button.cancel" />
          </Button>
          <Button
            variant="primary"
            disabled={userTypeNotFound}
            onClick={handleUserTypeChange}
          >
            <FormattedMessage id="button.confirm" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
