import * as Msal from "@azure/msal-browser";
import store from "../redux/store";
import {
  msalConfig,
  loginRequest,
  silentRequest,
  tokenRequest,
  resetPasswordRequest,
  passwordReset,
  changePasswordRequest
} from "./authConfig";
import LogRocket from "logrocket";

// Create the main msalApp instance
// configuration parameters are located at authConfig.js
const msalApp = new Msal.PublicClientApplication(msalConfig);

let accountId = "";
let accessToken = "";

msalApp
  .handleRedirectPromise()
  .then(handleResponse)
  .catch((error) => {
    console.error(error);

    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      try {
        msalApp.loginRedirect(resetPasswordRequest);
      } catch (err) {
        console.error(err);
      }
    }
    if (error.errorMessage.indexOf("AADB2C90091") > -1) {
      store.dispatch({
        type: "[Login] Action",
        payload: {
          isSigninInProgress: false,
        },
      });
    }
  });

function selectAccount() {
  const currentAccounts = msalApp.getAllAccounts();
  if (!currentAccounts || currentAccounts.length < 1) {
    store.dispatch({
      type: "[Logout] Action",
      payload: {
        isSigninInProgress: false,
      },
    });
  }
  else if (currentAccounts.length > 1) {
    console.warn("multiple user account detected.");

    let usableAccount = currentAccounts.find(curntAcc => { if (curntAcc?.idTokenClaims["tfp"] === "B2C_1_SignIn") return curntAcc; })
    if (usableAccount != null) {
      accountId = usableAccount.homeAccountId;
      store.dispatch({
        type: "[Login] Action",
        payload: {
          user: usableAccount,
          authToken: accessToken,
          userName: usableAccount.username,
          isSigninInProgress: false,
        },
      });
    }

  } else if (currentAccounts.length === 1) {
    accountId = currentAccounts[0].homeAccountId;
    store.dispatch({
      type: "[Login] Action",
      payload: {
        user: currentAccounts[0],
        authToken: accessToken,
        userName: currentAccounts[0].username,
        isSigninInProgress: false,
      },
    });
  }
}

function handleResponse(response) {
  if (response !== null) {
    if (response.accessToken != null) {
      accessToken = response.accessToken;
    }
    if (response.idTokenClaims["tfp"] === `${passwordReset}`) {
      // Choose which account to logout from by passing a username.
      const logoutRequest = {
        account: msalApp.getAccountByHomeId(accountId),
      };

      msalApp.logout(logoutRequest);
    } else {
      selectAccount();
    }
  } else {
    selectAccount();
  }
}

export const signIn = async () => {
  msalApp.loginRedirect(loginRequest);
  store.dispatch({
    type: "[Login] Action",
    payload: {
      isSigninInProgress: true,
    },
  });
};

export function signOut() {
  // selectAccount();
  // Choose which account to logout from by passing a username.
  localStorage.clear();
  LogRocket.startNewSession();
  const logoutRequest = {
    account: msalApp.getAccountByHomeId(accountId),
  };
  msalApp.logout(logoutRequest);
}

export function resetPassword() {
  msalApp.loginRedirect(resetPasswordRequest);

}
export function changePassword() {
  msalApp.loginRedirect(changePasswordRequest);
}

export const acquireAccessToken = async () => {
  const {
    auth: { user },
  } = store.getState();

  silentRequest.account = msalApp.getAccountByUsername(user.username);
  return msalApp.acquireTokenSilent(silentRequest)
    .then((response) => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken === "" || authToken === null || authToken === undefined) {
        store.dispatch({
          type: "[Set AuthToken] Action",
          payload: {
            authToken: response?.accessToken,
          },
        });
      }
      return response
    })
    .catch((error) => {
      console.warn(
        "silent token acquisition fails. acquiring token using interactive method"
      );
      if (error) {
        // fallback to interaction when silent call fails
        tokenRequest.account = msalApp.getAccountByUsername(user.userName);

        // return msalApp.acquireTokenRedirect(tokenRequest);
      } else {
        console.warn(error);
      }
    });
};
