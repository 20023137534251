import * as CryptoJS from "crypto-js";
import moment from "moment";
import { DataConstant } from "../app/constants/dataConstant";
import _ from "lodash";
import { utcToZonedTime } from 'date-fns-tz';

export const EncryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const encdata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
    keySize: 128 / 8,
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encdata.toString();
};

export const DecryptText = (text) => {
  const strKey = DataConstant.encryptDecryptKey;
  const key = CryptoJS.enc.Utf8.parse(strKey);
  const iv = CryptoJS.enc.Utf8.parse(strKey);
  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const validateTwoDecimalOnly = (e, places = 3) => {
  let t = e.target.value.toString();
  if (t.indexOf(".") >= 0) {
    t = t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), places)
    e.target.value = t;
  }
};

export const validateNoDecimal = (e) => {
  ignoreSignInNumber(e);
  if (e.keyCode === 190 || e.keyCode === 110) {
    e.preventDefault();
    return false;
  }
};

export const ignoreSignInNumber = (e) => {
  let kc = e.keycode || e.which;
  if (kc === 107 || kc === 109 || kc === 189 || kc === 187) {
    e.preventDefault();
    return false;
  } else return true;
};
export const validateOnlyNumber = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const validateOnlyNumberWithDot = (e) => {
  var x = parseInt(e.which || e.keycode);
  if (
    (x >= 48 && x <= 57) ||
    (x >= 96 && x <= 105) ||
    x === 8 ||
    x === 37 ||
    x === 39 ||
    x === 9 || x === 46 || x === 110 || x === 190
  ) {
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const handleEmptySpace = (e) => {
  let t = e.target.value.trim();
  e.target.value = t ? e.target.value : null;
};

export const assignMomentDateTime = (dateTime, browser) => {

  switch (browser?.browserPlatform) {
    case DataConstant.browserPlatform.MacIntel:
      return moment(String(dateTime), DataConstant.dateFormats.mmddyyyy).format(
        DataConstant.dateFormats.ddmmyyyy
      );

    default:
      return moment(String(dateTime)).format(
        DataConstant.dateFormats.ddmmyyyy
      );
  }

}

export const setMomentDateTime = (dateTime) => {
  if (window.navigator.platform.toLowerCase().includes('win') && (typeof InstallTrigger !== 'undefined') === false) {
    return moment(dateTime).format(
      DataConstant.dateFormats.ddmmyyyy
    )
  }
  else {
    if (moment(dateTime, DataConstant.dateFormats.ddmmyyyy).isValid()) {
      return moment(String(dateTime), DataConstant.dateFormats.ddmmyyyy).format(DataConstant.dateFormats.ddmmyyyy);
    }
    else {
      return moment(String(dateTime), "").format(DataConstant.dateFormats.ddmmyyyy);
    }
  }
}


export const IsValidDate = (date) => {
  if (Object.prototype.toString.call(date) === "[object Date]") {
    // it is a date
    if (isNaN(date)) { // d.getTime() or d.valueOf() will also work
      // date object is not valid
      return false;
    } else {
      // date object is valid
      return true;
    }
  } else {
    // not a date object
    return false;
  }
}

export const subtractMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
}

export const addMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() + numOfMonths);
  return date;
}

export const convertToLocalTime = (datestring, format = DataConstant.dateFormats.mmddyyyyHHmmss, displayFormat = DataConstant.dateFormats.mmddyyyy_hhmmA) => {
  if (!datestring) return '-';
  var utcDateTime = moment.utc(datestring, format)
  return moment(utcDateTime).local().format(displayFormat);
}

export const convertToLocalFromUTC = (datestring, displayFormat = DataConstant.dateFormats.mmddyyyy_hhmmA) => {
  if (!datestring) return '-';
  var utcDateTime = moment.utc(datestring)
  return moment(utcDateTime).local().format(displayFormat);
}

export const convertUtcToZonedTime = (datestring, toTZ, displayFormat = DataConstant.dateFormats.DDMMyyyyHHmmss) => {
  if (!datestring || !toTZ) return "-";
    const zonedDateTime = utcToZonedTime(new Date(datestring), toTZ);
    return moment(zonedDateTime).format(displayFormat);
};

export const sortOnKey = (_array, keyName) => {
  if (keyName === null || keyName === undefined) return _array;

  return _array.sort((a, b) => {
    if (a[keyName] < b[keyName]) { return -1 }
    else if (a[keyName] > b[keyName]) { return 1 }
    else return 0;
  })

}

export const convertPhraseToURLCompatible = (_string) => {

  _string = _string?.replace('%', 'PERCNET');

  _string = encodeURIComponent(_string);

  return _string;

}
export const convertURLToPhraseCompatible = (_string) => {

  _string = decodeURIComponent(_string);

  _string = _string?.replace('PERCNET', '%');

  return _string;
}
export const IsValidNumber = (value) => {
  let isvalid = true;
  if (value === null || value === undefined || value === 0) {
    isvalid = false;
  }
  return isvalid;
};

export const getValidDateOnCrossPlatform = (_date, _format = DataConstant.dateFormats.ddmmyyyy) => {

  //to generate local date format
  var now = new Date(2023, 11, 31);
  var localformat = now.toLocaleDateString();
  localformat = localformat.replace("31", "DD");
  localformat = localformat.replace("12", "MM");
  localformat = localformat.replace("2023", "YYYY");

  return moment(_date, localformat).format(_format);
}

export const convertListToDropdownList = (_list, _labelKeyName, _valueKeyName) => {
    if (_list && _list.length > 0 && _labelKeyName && _valueKeyName) {
        return _list.map((_item) => {
            return {
                label: _item[_labelKeyName],
                value: _item[_valueKeyName]
            }
        })

    }
    else return [];
}

/**
 * Converts a numerical value to its ordinal representation appended with a provided index.
 * @param {number} index - The numerical value to be converted to its ordinal representation.
 * @returns {string} The ordinal representation of the value appended with the index.
 * @example
 * // Example usage:
 * console.log(getNthValueText(22)); // Output: "22nd"
 * console.log(getNthValueText(17)); // Output: "17th"
 * console.log(getNthValueText(3)); // Output: "3rd"
 * console.log(getNthValueText(31)); // Output: "31st"
 */
export const getNthValueText = (index=0) => {
	if (!index) return "";
	const suffixes = ["th", "st", "nd", "rd"];
	const intValue = parseInt(index, 10);
	const suffix = (intValue % 100 > 10 && intValue % 100 < 14) ? suffixes[0] : suffixes[intValue % 10] || suffixes[0];
	return `${intValue}${suffix}`;
};

const avoidingDuplicateMergeArray = (array1, array2) => {
  let mergeArray = array1.concat(array2);
  mergeArray = [...new Set([...array1, ...array2])];
  return mergeArray;
};

export const getLocationInfo = (results) => {
  let city = "", zipcode = [], addressJson = {};
  addressJson.fullAddress = results?.length > 0 ? results[0]?.formatted_address : null;
  for (let i = 0; i < results?.length; i++) {
    let validAddress = false;
    if (results[i]?.types?.length > 0 && (results[i]?.types?.includes("street_address") || results[i]?.types?.includes("premise") || results[i]?.types?.includes("subpremise") || results[i]?.types?.includes("establishment"))) {
      validAddress = true;
    }
    for (var j = 0; j < results[i]?.address_components?.length; j++) {
      var types = results[i]?.address_components[j]?.types.join(",");
      if (types === "street_number") {
        addressJson.street_number = results[i]?.address_components[j]?.long_name;
      }
      if (types === "route" || types === "point_of_interest,establishment") {
        addressJson.route = results[i]?.address_components[j]?.long_name;
      }
      if (types === "sublocality,political" || types === "locality,political" || types === "neighborhood,political" || types === "administrative_area_level_3,political") {
        addressJson.city = (city === "" || types === "locality,political") ? results[i]?.address_components[j]?.long_name : city;
      }
      if (types === "administrative_area_level_1,political") {
        addressJson.state = results[i]?.address_components[j]?.short_name;
      }
      if (types === "postal_code" || types === "postal_code_prefix,postal_code") {
        let currentZipCode = results[i]?.address_components[j]?.long_name;
        if (!_.isEmpty(currentZipCode) || !_.isUndefined(currentZipCode)) {
          zipcode = avoidingDuplicateMergeArray(zipcode, [currentZipCode]);
        }
        addressJson.zipcode = zipcode?.toString();
      }
      if (types === "country,political") {
        addressJson.country = results[i]?.address_components[j]?.long_name;
      }
    }
    addressJson.validAddress = validAddress;
  }
  return addressJson;
};

// This function will remove if there are any spaces between stateName 
// For example it will convert  (Himachal Pradesh => himachalpradesh)
export const stateNameWithoutSpaces = (stateName)=>{
	return stateName.replace(/\s+/g, '').toLowerCase();
}

export const compareArrays = (a, b) => {
  // Extract value fields from both arrays
  const aValues = a.map((item) => item.value);
  const bValues = b.map((item) => item.value);

  // Find newly added values in 'a' that are not in 'b'
  const newlyAdded = aValues.filter((value) => !bValues.includes(value));

  // Find missing values in 'a' that are in 'b'
  const missing = bValues.filter((value) => !aValues.includes(value));

  return {
    newlyAdded,
    missing,
  };
}

export const roundToFixedDecimal = (number, decimalPoints = DataConstant.priceFloatingPoints) => {
  if(!number && number !== 0) return null;
	return Number(number)?.toFixed(decimalPoints);
};

export const IsValidString = (value) => {
  let isvalid = true;
  if (value === null || value === undefined || value === '') {
    isvalid = false;
  }
  return isvalid;
};

export const addSpacesInArray = (array, joinString = " ") => {
  return array?.filter((a) => a)?.join(joinString);
};

export const getAddress = ({
  address1,
  address2,
  state,
  city,
  zipcode,
  clientName,
  googleMapJson
}) => {
  if(googleMapJson){
    try{
      const formatted_address = JSON.parse(googleMapJson)?.formatted_address;
      return [clientName, formatted_address].filter(a => a)?.join(", ");
    }catch(error){
      console.error("Error parsing googleMapJson:");
    }
  }
  else{
    return [
      clientName,
      address1,
      address2,
      city,
      `${addSpacesInArray([state, zipcode])}`,
    ]
      .filter((a) => a)
      ?.join(", ");
  }
};

export const getServiceAddOnList = (selectedServiceList, selectedAddOnList) => {
  let allServices = [];
  selectedServiceList?.length > 0 && selectedServiceList.map((item) => {
    for (let i = 0; i < item?.qty; i++) {
      let object = { ...item, qty: 1, considerMembership: i < item?.considerMembership ? 1 : 0 };
      allServices = [...allServices, object];
    }
  });
  selectedAddOnList != null && selectedAddOnList?.length > 0 && selectedAddOnList.map((item) => {
    for (let i = 0; i < item?.qty; i++) {
      let object = { ...item, qty: 1 };
      allServices = [...allServices, object];
    }
  });
  return allServices;
};

export const getServiceTotal = (data) => {
  return data?.reduce((a, b) => +a + +b?.servicePrice, 0);
};

export const discountCalculation = (data, isFromMembership, totalAmount) => {

  let paymentDetails = data?.membershipDiscountType;
  let hasData = (paymentDetails === DataConstant.salesReport.membershipDiscountType.service || paymentDetails === DataConstant.salesReport.membershipDiscountType.service);

  let iscreditDiscountAmount = data?.creditDiscountAmount > 0;
  let isgroupDiscountAmount = data?.groupDiscountAmount > 0;
  let iscouponDiscountAmount = data?.couponDiscountAmount > 0;
  let isrewardClubDiscountAmount = data?.rewardClubDiscountAmount > 0;
  let total = totalAmount;

  if (iscreditDiscountAmount && !isFromMembership && !hasData) {
    total = total - data?.creditDiscountAmount;
  }
  if (isgroupDiscountAmount) {
    total = total - data?.groupDiscountAmount;
  }
  if (iscouponDiscountAmount) {
    total = total - data?.couponDiscountAmount;
  }
  if (isrewardClubDiscountAmount) {
    total = total - data?.rewardClubDiscountAmount;
  }
  return total;
};

export const arrayToString = (data) => {
  let formatedString = "";
  if (data && data.length > 0) {
    data.map((item) => {
      formatedString = formatedString + item + ",";
    });
  }
  return formatedString;
};

export const savePreBookingArrayToString = (data) => {
  let formatedString = "";
  let arrangeData = _.orderBy(data, ["sequenceNo"], ["asc"]);
  arrangeData?.length > 0 && arrangeData.map((item) => {
    formatedString = formatedString + item?.discountType + ",";
  });
  return formatedString;
};

export const sequenceManger = (data, isAdd, isRemove, key) => {
  let isFound = false;
  let newData = data;
  newData.length > 0 && newData.map((item) => {
    if (item === key) {
      isFound = true;
    }
  });
  if (key?.includes(DataConstant.sequenceData.Credit) || key?.includes(DataConstant.sequenceData.Coupon)) {
    if (isAdd) {
      if (!isFound) {
        newData = [...newData, key];
      }
    } else if (isRemove) {
      newData = newData?.filter(i => i !== key);
    }
  }
  return newData;
};

/**
 * Exports the given data as a CSV file to the user's computer.
 *
 * @param {Object} options
 * @param {Object[]} options.keysAndHeaders - A list of objects with keys "headerName" and "key". "headerName" is the header name of the column and "key" is the
 *                                            key of the field in the data objects also formater can be passed to format the data.
 * @param {Object[]} options.tableData - The data to be exported as a CSV file.
 * @param {String} options.fileName - The name of the CSV file.
 * @param {Boolean} [options.showGeneratedTime=false] - Whether to add the "Generated Time" row at the top of the CSV file.
 * @param {Date|String} [options.timeStamp] - The timestamp to be used for the "Generated Time" row. If not provided, the current time is used.
 * @option {Function} [formatter] - A function that takes the value of the current cell, the current row object and the entire data array as arguments and returns
 *                                   the formatted value of the cell. If not provided, the value is used as is. If the value is an object, it is stringified as
 *                                   a JSON object. If the value is undefined or null, an empty string is used.
 *
 * @example
 * import { localCsvExport } from "utils/helper";
 *
 * localCsvExport({
 *   keysAndHeaders: [
 *     { headerName: "Name", key: "name" },
 *     { headerName: "Age", key: "age" },
 *     { headerName: "Gender", key: "gender", formatter: (value, row, data) => value === 1 ? "Male" : "Female" },
 *   ],
 *   tableData: [
 *     { name: "John Doe", age: 30, gender: 1 },
 *     { name: "Jane Doe", age: 25, gender: 0 },
 *   ],
 *   fileName: "example",
 *   showGeneratedTime: true,
 * });
 */
export const localCsvExport = async ({ keysAndHeaders, tableData=[], fileName, showGeneratedTime=false, timeStamp }) => {
  try {
    const data = tableData;

    if (!data|| data.length <= 0) {
      return;
    }
    const csvRows = [];
    const headers = keysAndHeaders.map(({ headerName }) => headerName);
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const row = keysAndHeaders?.map(({ key, formatter }) => {
        const value = item?.[key];

        if (formatter) {
          return formatter(value, item, data);
        } else if (typeof value === "object") {
          return JSON.stringify(value);
        } else if (value === undefined || value === null) {
          return "";
        } else {
          return value;
        }
      });
      csvRows.push(row.join(","));
    });

    // Get the current time for "Generated Time"
    const generatedTime = moment(timeStamp || new Date()).format("YYYY-MM-DD HH:mm:ss");

    // Prepare the "Generated Time" row
    const generatedTimeRow = [`Generated Time: ${generatedTime}`];

    if (showGeneratedTime) {
      csvRows.push(generatedTimeRow); // Add at the top
    }

    // Create CSV content
    const csvContent = csvRows.join("\n");

    // Download the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  } catch (error) {
    console.error("Error generating CSV", error);
  }
};
