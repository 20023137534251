
export const DataConstant = {

  zipCodeDefaultValues: {
    zipCodePattern: /^\d{5}(-\d{4})?$/,
    zipCodeMaxLength: 10
  },
  currencies: {
    "CAD": "C$",
    "USD": "$"
  },
  encryptDecryptKey: "fl!23net@%$$2!@#",
  defaultSorted: [{ dataField: "id", order: "asc" }],
  sizePerPageList: [
    { text: "50", value: 50 },
    { text: "100", value: 100 },
  ],
  pagingInfo: {
    searchColumn: {
      columnName: "",
      searchString: "",
      columnDataType: "",
    },
    sortOrder: "desc",
    sortField: "",
    page: 1,
    itemsPerPage: 50,
    searchText: "",
  },
  apiResponseStatus: {
    success: "Success",
  },
  commonYesNoDropDownList: [
    { label: "No", value: false },
    { label: "Yes", value: true },
  ],
  commonStatus: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "InActive" },
  ],
  supportDataType: {
    onboardingStep: "OnboardingStep",
  },
  serviceCategory: {
    moduleName: "Service category",
    mediaType: 4,
    serviceCategoryStatusList: [
      { type: "Active", displayName: "Active" },
      { type: "InActive", displayName: "Inactive" },
    ],
  },
  parentBusiness: {
    moduleName: "Parent Business",
    mediaType: 4,
    serviceCategoryStatusList: [
      { type: "Active", displayName: "Active" },
      { type: "InActive", displayName: "Inactive" },
    ],
  },
  serviceGroup: {
    moduleName: "Service Group",
    whatToLink: [
      { type: "Consult", displayName: "Consult" },
      { type: "Labs", displayName: "Lab test" },
    ]
  },
  subscription: {
    moduleName: "subscription",
    subscriptionStatusList: [
      { type: "Active", displayName: "Active" },
      { type: "InActive", displayName: "Inactive" },
    ],
    paymentPeriod: [
      { type: "Monthly", displayName: "Monthly" },
      { type: "Annually", displayName: "Upfront" },
    ],
    allowedRole: [
      "Client",
      "Service Provider",
      "Admin",
      "Receptionist",
      "Affiliate",
      "MD",
      "Business HCP",
      "Standalone HCP",
    ],
    userType: {
      hcp: "HCP",
      businessHCP: "Business HCP",
    },
  },
  state: {
    moduleName: "State",
  },
  country: {
    moduleName: "Country",
  },
  defaultCountry: "United States",
  note: {
    moduleName: "Note",
    noteRefType: {
      business: 1,
      serviceCategory: 2,
      service: 3,
      facility: 4,
      addOn: 5,
      serviceProvider: 6,
      users: 7,
      healthcareProfessional: 8,
      location: 9,
      event: 10,
      giftCard: 11,
      spotlight: 12,
      news: 13,
      payout: 14,
      clientReport: 21
    },
  },
  business: {
    moduleName: "Business",
    businessStatusList: [
      { businessStatus: "Active", displayName: "Active" },
      { businessStatus: "InActive", displayName: "Inactive" },
      { businessStatus: "Suspended", displayName: "Suspended" },
    ],
    commissiontype: [
      { name: "Fixed Amount", value: "D" },
      { name: "%", value: "P" },
    ],
    commissionCalculateList: [
      { name: "Cost Per Booking", value: "Booking" },
      { name: "Cost Per Service", value: "Service" },
      { name: "Cost Per Prescription", value: "Prescription" },
    ],
    commissionBasedOnList: [{ basedOn: "Net Profit" }, { basedOn: "Gross" }],
    commissionTypeList: {
      netProfit: "Net Profit",
      gross: "Gross",
    },
    bookingType: {
      facility: "Facility",
      callout: "Callout",
      medicalConsult: "MedicalConsult",
    },
    businessProfileType: {
      businessProfileImage: "businessProfileImage",
      businessBannerImage: "businessBannerImage",
      businessListingImage: "businessListingImage",
    },
    businessCSVColumnName:
      "Name,DefaultAdmin,Email,Phone,BusinessStatus,IsTestBusiness,BusinessCurrencyCode",
    medicalConsultStatusList: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    bookingTypeBasedCommission: {
      callout: "Callout",
      facility: "Facility",
      onTheSpot: "OnTheSpot",
      medicalConsult: "MedicalConsult",
    },
  },
  userType: {
    MasterAdmin: "Master Admin",
    MD: "MD",
    Admin: "Admin",
    Client: "Client",
    Nurse: "Nurse",
    SuperAdmin: "Super Admin",
    Doctor: "Doctor",
    serviceProvider: "Service Provider",
    hcp: "HCP",
    InsurancePartner: "Insurance Partner",
    Receptionist: "Receptionist",
    DoctorNetworkMD: "Doctor Network Admin",
    Pharmacy: "Pharmacy",
  },
  userTypeUrlConstant: {
    adminStatus: "Admin",
    serviceProviderStatus: "Service Provider",
    hpsStandalone: "Standalone HCP",
    hpsBusiness: "HCP",
    medicalDirectorStatus: "MD",
    receptionistStatus: "Receptionist",
    affiliateStatus: "Affiliate",
    clientStatus: "Client"
  },
  onboardingFlowStepStatus: {
    Completed: "Completed",
    ApprovalRequested: "Approval Requested"
  },
  professionType: {
    sp: "ServiceProvider",
    hcp: "HCP",
  },
  alphaNumericPattern: /^[a-zA-Z0-9\-\s]+$/i,
  pdfPattern: /\.(pdf)$/,
  emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  imagePattern: /\.(jpg|jpeg|png)$/,
  imagePdfPattern: /\.(jpg|jpeg|png|pdf)$/,
  dEANumberPattern: /^[A-Z][A-Z9][0-9]{7}(-[A-Z0-9]{1,7})?$/,
  notificationTimeout: 5000,
  defaultCurrencySymbol: "$",
  defaultCurrencyCode: "USD",
  csvPattern: /\.(csv)$/,
  decimalRegex: /^\d{1,3}(\.\d{1,1})?$/, // Max length should be 3 numbers before decimal and 1 digits after decimal,
  charactersWithoutSpace: /^\S*$/,
  onboarding: {
    moduleName: "Onboarding",
    onBoardingStatus: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    onboardingAccountOptions: [
      { label: "Include Subscription", value: true },
      { label: "Do Not Include Subscription", value: false },
    ],
    onboardingFormLinkingStatus: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    onBoardingFirstTimeLoginStatus: [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ],
    onBoardingFirstTimeLoginFrequency: [
      { label: "First Time Only", value: "FirstTimeOnly" },
      { label: "All New Logins", value: "AllNewLogins" },
    ],
    onboardingStepStatus: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    onboardingStepsType: {
      TermsAndConditions: "TermsAndConditions",
      InternalLink: "InternalLink",
      ExternalLink: "ExternalLink",
      Form: "Form",
      Training: "Training",
    },
    allowedRole: [
      "Client",
      "Service Provider",
      "Admin",
      "Receptionist",
      "Affiliate",
      "MD",
      "Business HCP",
      "Standalone HCP",
      "Doctor Network Admin",
    ],
    userType: {
      hcp: "HCP",
      businessHCP: "Business HCP",
      standaloneHCP: "Standalone HCP",
    },
    onBoardingApprovalStatus: {
      Approve: "Approve",
      Approved: "Approved",
      ApprovalRequested: "ApprovalRequested",
    },
    onBoardingProgessStatus: {
      Completed: "Completed",
      Pending: "Pending",
    },
    onBoardingRoles: {
      Receptionist: 'Receptionist'
    },
    userStatus: [
      { label: "Live", value: "Live" },
      { label: "Not Live", value: "NotLive" },
    ],
  },
  featurePermission: {
    refType: {
      business: 1,
      subscriptionPlan: 2,
    },
  },
  mediaType: {
    businessProfileImage: 1,
    businessBannerImage: 2,
    businessListingImage: 3,
    serviceCategoryPics: 4,
    businessPaperDcoument: 5,
    serviceInstructionDocument: 14,
    serviceStandingOrderDocument: 15,
    serviceProviderPaperDcoument: 17,
    bookingInSessionDocument: 27,
    servicePackReadOnlyFormDocument: 38,
    spotlightPics: 29,
    newsPics: 30,
    serviceMenuPics: 6,
    serviceListingPics: 7,
    homeScreenDiscountImage: 23,
    homeScreenSpotlightImage: 24,
    CkEditor: 44,
    ClientDocumentBySP: 49,
    parentBusinessProfileImage: 57,
  },
  fileBaseFolder: {
    business: "Business",
    serviceCategory: "ServiceCategory",
    service: "Service",
    serviceProvider: "ServiceProvider",
    servicePackReadOnlyForm: "ServicePackReadOnlyForm",
    bookingInSessionDocument: "BookingInSessionDocument",
    ckEditor: "Training"
  },
  facility: {
    moduleName: "Facility",
    facilityStatus: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    facilityProfileType: {
      facilityProfileImage: "facilityProfileImage",
      facilityBannerImage: "facilityBannerImage",
      facilityListingImage: "facilityListingImage",
    },
    bookingType: {
      facility: "Facility",
    },
    serviceCategory: {
      service: "Service",
      addOn: "AddOn",
    },
  },
  service: {
    moduleName: "Service",
    serviceStatusList: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
      { label: "Suspended", value: "Suspended" },
    ],
    bookingType: {
      callout: "Callout",
      facility: "Facility",
      onTheSpot: "On the Spot",
    },
    serviceImages: {
      serviceMenuImage: "serviceMenuImage",
      serviceListingImage: "serviceListingImage",
    },
    commissionlevel: [
      { label: "Gold", value: "Gold" },
      { label: "Silver", value: "Silver" },
    ],
    labValidList: [
      { label: "Weeks", value: "Weeks" },
      { label: "Months", value: "Months" },
      { label: "Years", value: "Years" },
    ],
    consultationValidList: [
      { label: "Weeks", value: "Weeks" },
      { label: "Months", value: "Months" },
      { label: "Years", value: "Years" },
    ],
    medExpiryList: [
      { label: "Days", value: "Days" },
      { label: "Weeks", value: "Weeks" },
      { label: "Months", value: "Months" },
      { label: "Years", value: "Years" },
    ],
    checkinTimingsList: [
      { label: "At The Beginning", value: "At the beginning" },
      { label: "To Refill Medications", value: "To Refill Medications" },
      { label: "All of the above", value: "All of the above" },
    ],
    labSourcesList: [
      { label: "WWL", value: "WWL" },
      { label: "Access Labs", value: "Access Labs" },
    ],
    reAssessmentList: [
      { label: "Weeks", value: "Weeks" },
      { label: "Bookings", value: "Bookings" },
      { label: "Months", value: "Months" },
    ],
    medUnitList: [
      { label: "mg", value: "mg" },
      { label: "ml", value: "ml" },
      { label: "cc", value: "cc" },
      { label: "patch", value: "patch" },
      { label: "units", value: "units" },
    ],
    commissionLevelList: [
      { label: "Level 1", value: "Level 1" },
      { label: "Level 2", value: "Level 2" },
      { label: "Level 3", value: "Level 3" },
      { label: "Level 4", value: "Level 4" },
      { label: "Level 5", value: "Level 5" },
      { label: "Level 6", value: "Level 6" }
    ],
    businessAvailibility: { Specific: "Specific", All: "All" },

    serviceTypeList: [
      { label: "Service", value: "Service" },
      { label: "Add On", value: "AddOn" },
    ],
    serviceType: {
      service: "Service",
      addOn: "AddOn",
    },
    hippaServicePack: "hippa",
    covidServicePack: "covid",
  },
  serviceProvider: {
    moduleName: "serviceProvider",
    gender: [
      { label: "Male", value: "M" },
      { label: "Female", value: "F" },
      { label: "Other", value: "O" },
    ],
    commissionShareList: [
      { label: "Commission", value: "Commission" },
      { label: "Profit Share", value: "Profit Share" },
    ],
    serviceProviderCSVColumnName:
      "Username,Status,IsTestAccount,Phone,Email,DOB,VerificationStatus",
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    times: [
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
      "00:00",
    ],
    workingType: {
      facility: "Facility",
      callout: "Callout",
      both: "Both",
    },
    prefix: [
      { label: "Dr.", value: "Dr." },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
      { label: "Miss.", value: "Miss." },
    ],
    verificationList: [
      { label: "Verified", value: 1 },
      { label: "Unverified", value: 2 },
    ],
    commissiontype: [
      { label: "%", value: "P" },
      { label: "$", value: "D" },
    ],
    medicalScreeningCommisssionLevel: [
      { label: "Level 1", value: "Level 1", defaultValue: 25 },
      { label: "Level 2", value: "Level 2", defaultValue: 40 },
      { label: "Level 3", value: "Level 3", defaultValue: 10 },
      { label: "Level 4", value: "Level 4", defaultValue: 6 },
    ],
    consultType: {
      serviceConsult: "Service Consult",
      medicalConsult: "Medical Consult",
      standaloneHCP: "Standalone HCP",
    },
    commissionTypeValue: {
      fixedAmount: "D",
      percentage: "P",
    },
    advanceBookingTimeType: [
      { label: "Hours", value: "Hours" },
      { label: "Minutes", value: "Minutes" },
    ],
    hcpType: [
      { label: "Business", value: "Business" },
      { label: "Standalone", value: "Standalone" },
    ],
    commissionValueTypeForClinic: [
      { label: "Fixed Amount", value: "D" },
      { label: "%", value: "P" },
      { label: "Gross", value: "G" },
    ],
    age: 18,
    advanceBookingTimeTypeValue: {
      Hours: "Hours",
      Minutes: "Minutes",
    },
    type: {
      serviceProvider: "sp",
      healthcareProfessional: "hcp",
    },
    commissionLevelList: {
      "level1": "Level 1",
      "level2": "Level 2",
      "level3": "Level 3",
      "level4": "Level 4",
      "level5": "Level 5",
      "level5-chatonly": "Level 5 - Chat Only",
      "level6": "Level 6" 
    },
    isTestAccountDropdown: [
      { label: "Live", value: 'false' },
      { label: "Not Live", value: 'true' },
    ],
    isBackupHCP: [
      { label: "HCPs", value: 'false' },
      { label: "Backup HCPs", value: 'true' }
    ]
  },
  pdfFileSize: "5242880", // 5 MB
  imageFileSize: "2097152", // 2 MB
  serviceType: {
    service: "Service",
    addOn: "AddOn",
    sync: "Sync",
    async: "Async",
  },
  psfLabBasedStatus: {
    LABTESTBOOKED : "Lab Test Booked",
    LABTESTINPROGRESS : "Lab Test In Progress",
    LABREPORTRECEIVED: "Lab Report Received",
    LABCOMPLETED: "Lab Completed",
    CSPSESSIONBOOKED: "Csp Session Booked",
    CSPSESSIONINPROGRESS: "Csp Session In Progress",
    CSPSESSIONCOMPLETED: "Csp Session Completed"
  },
  platFormFee: {
    platFormServiceFeeType: [
      { name: "Fixed Amount ($)", value: "D" },
      { name: "Percentage (%)", value: "P" },
    ],
    platFormServiceFeeTypeValue: {
      fixedAmount: "D",
      per: "P",
    }
  },
  coupon: {
    discountType: [
      { name: "Fixed Amount ($)", value: "D" },
      { name: "Percentage (%)", value: "P" },
    ],
    businessType: [
      { name: "All", value: "All" },
      { name: "Specific", value: "Specific" },
      { name: "Combine ", value: "Combine " },
    ],
    serviceType: [
      { name: "All", value: "All" },
      { name: "Specific Services", value: "SpecificServices" },
      {
        name: "Specific Services and Add Ons",
        value: "SpecificServicesAndAddons",
      },
    ],
    couponType: "Promo",
    serviceTypeAvailibility: {
      SpecificServices: "SpecificServices",
      All: "All",
      SpecificServicesandAddOns: "SpecificServicesAndAddons",
    },
    serviceTypeValue: "Service",
    couponStatus: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
      { label: "Expired", value: "Expired" },
    ],
    couponTypeForFilter: [
      { label: "Promo", value: "Promo" },
      { label: "Referral Coupon", value: "Referral Coupon" },
      { label: "Referral Code", value: "Referral Code" },
    ],
    discountTypeValue: {
      fixedAmount: "D",
      per: "P",
    },
    status: [
      { label: "Upcoming", value: "BookingAccepted" },
      { label: "In Session", value: "SessionInProgress" },
      { label: "Completed", value: "Completed" },
      { label: "Booking Cancelled", value: "BookingCancelled" },
      { label: "Pending Medication Delivery", value: "PSSBookingUpcomingMedicationOrdered" },
      { label: "Medication Delivered", value: "PSSBookingUpcomingMedicationDelivered" },
      { label: "Session Upcoming", value: "SessionUpcoming" },
      { label: "Session Completed", value: "SessionCompleted" },
      { label: "Self Medication Program Upcoming", value: "SelfMedicationProgramUpcoming" },
      { label: "Pending", value: "Pending" },
      { label: "Scheduled", value: "Scheduled" },
      { label: "InComplete", value: "InComplete" },
      { label: "Cancelled", value: "Cancelled" },
      { label: "Not Available", value: "NotAvailable" },
      { label: "Lapsed", value: "Lapsed" },
      { label: "Rejected", value: "Rejected" },
      { label: "In Progress", value: "InProgress" },
      { label: "Checkin In Progress", value: "CheckinInProgress" },
      { label: "Checkin Approved", value: "CheckinApproved" },
      { label: "Checkin Denied", value: "CheckinDenied" },
      { label: "Checkin Cancelled", value: "CheckinCancelled" },
      { label: "Lab Booked", value: "LabBooked" },
      { label: "Lab Received", value: "LabReceived" },
      { label: "Lab In Progress", value: "LabInProgress" },
      { label: "Awaiting For Consultation", value: "AwaitingConsultation" },
      { label: "Consultation", value: "Consultation" },
      { label: "Self Admin Program", value: "SelfAdminProgramUpcoming" },
      { label: "Lab Cancelled", value: "LabCancelled" },
      { label: "Consultation Complete", value: "ConsultationComplete" },
      { label: "CSP Session Booked", value: "CspSessionBooked" },
      { label: "CSP Session In Progress", value: "CspSessionInProgress" },
      { label: "CSP Session Completed", value: "CspSessionCompleted" },
    ],

    displayStatus: {
      BookingAccepted: "Upcoming",
      SessionInProgress: "In Session",
      Completed: "Completed",
      BookingCancelled: "Booking Cancelled",
      PendingMedicationDelivery: "Pending Medication Delivery",
      MedicationDelivered: "Medication Delivered",
      SessionUpcoming: "Session Upcoming",
      SessionCompleted: "Session Completed",
      SelfMedicationProgramUpcoming: "Self Medication Program Upcoming",
      Pending: "Pending",
      Scheduled: "Scheduled",
      InComplete: "InComplete",
      Cancelled: "Cancelled",
      NotAvailable: "Not Available",
      Lapsed: "Lapsed",
      Rejected: "Rejected",
      InProgress: "In Progress",
      CheckinInProgress: "Checkin In Progress",
      CheckinApproved: "Checkin Approved",
      CheckinDenied: "Checkin Denied",
      CheckinCancelled: "Checkin Cancelled",
      LabBooked: "Lab Booked",
      LabReceived: "Lab Received",
      LabInProgress: "Lab In Progress",
      AwaitingConsultation: "Awaiting For Consultation",
      Consultation: "Consultation",
      SelfAdminProgramUpcoming: "Self Admin Program",
      LabCancelled: "Lab Cancelled",
      ConsultationComplete: "Consultation Complete",
      CspSessionBooked: "CSP Session Booked",
      CspSessionInProgress: "CSP Session In Progress",
      CspSessionCompleted: "CSP Session Completed"
    },
    type: [
      { label: "Callout", value: "Callout" },
      { label: "Facility", value: "Facility" },
    ],
    couponUsageCSVColumnName:
      "OrderNo,OrderStatus,BookingUTCDateTime,ServiceProviderName,ServiceProviderEmail",
    allCouponUsageCSVColumnName:
      "OrderNo,OrderStatus,CouponName,CouponCode,DiscountType,Amount,BusinessName,ClientName",
  },
  dateFormats: {
    ddmmyyyy: "DD/MM/YYYY",
    ddmmyyyy_dash: "DD-MM-YYYY",
    mmddyyyy: "MM/DD/YYYY",
    yyyymmdd: "YYYY/MM/DD",
    yyyyMMDD: "yyyy-MM-DD",
    hhmm: "hh:mm",
    HHmmss: "HH:mm:ss",
    hhmmss: "hh:mm:ss",
    hhmmssa: "hh:mm:ss A",
    ddmmyyyyhhmmss: "dd/MM/yyyy HH:mm:ss",
    mmddyyyyHHmmss: "MM/DD/YYYY HH:mm:ss",
    DDMMyyyyHHmmss: "DD/MM/YYYY HH:mm:ss",
    DDMMyyyyHHmmss_Dash: "DD/MM/YYYY HH:mm:ss A",
    mmddyyyy_hhmmA: "MM/DD/YYYY HH:mm:ss A",
    mmddyyyy_hhmmSP: "YYYY-MM-DDTHH:mm:ss",
    mmddyyyy_hhmmssa: "MM/DD/YYYY hh:mm:ss A",
    DoMMMYYYY: "Do MMM YYYY",
    YYYYMMDDTHH:"YYYY-MM-DDTHH:mm:ss.SSS[Z]",
    YYYYMMDD: "YYYY-MM-DD",
    DoDD_HHmmssa: "MMM DD hh:mm:ss A",
  },
  countryStatus: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ],
  stateStatus: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ],
  userStatus: [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Reject", value: "Reject" },
    { label: "Deleted", value: "Deleted" },
    {
      label: "Approval Pending by Admin",
      value: "ApprovalPendingbyAdmin",
    },
    { label: "Rejected by Admin", value: "RejectedbyAdmin" },
    { label: "Approval Pending by MD", value: "ApprovalPendingbyMD" },
    { label: "Rejected by MD", value: "RejectedbyMD" },
    { label: "Blocked", value: "Blocked" },
    { label: "Suspended", value: "Suspended" },
  ],

  userStatusForAdmin: [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
    { label: "Reject", value: "Reject" },
    {
      label: "Approval Pending by Admin",
      value: "ApprovalPendingbyAdmin",
    },
    { label: "Rejected by Admin", value: "RejectedbyAdmin" },
    { label: "Approval Pending by MD", value: "ApprovalPendingbyMD" },
    { label: "Rejected by MD", value: "RejectedbyMD" },
    { label: "Blocked", value: "Blocked" },
    { label: "Suspended", value: "Suspended" },
  ],
  user: {
    allowedRole: ["Master Admin", "Admin", "MD", "Client", "Insurance Partner", "Doctor Network Admin", "Pharmacy"],
    userType: {
      MasterAdmin: "Master ADmin",
      Admin: "Admin",
      MD: "MD",
      Client: "Client",
      InsurancePartner: "Insurance Partner",
      DoctorNetworkMD: "Doctor Network Admin",
      Business: "Business",
      Pharmacy: "Pharmacy",
    },
    userCSVColumnName: "UserName,Status,UserType,Email,Phone,Business",
  },
  inputFileType: {
    image: "image/jpeg",
    spreadsheet:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    pdf: "application/pdf",
    document:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  consentTreatmentType: {
    medicalConsent: "Medical Consent",
    serviceConsent: "Service Consent",
    serviceConsult: "Service Consult",
  },
  clientBookingChoice: {
    position: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
    ],
    bookingTypeName: {
      medicalConsult: "MedicalConsult",
      mobileCalloutService: "MobileCalloutService",
      facilityService: "FacilityService",
    },
  },
  membershipPlan: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    planType: [
      { label: "Services", value: "services" },
      { label: "Credits", value: "credits" },
    ],
    planTypeValue: {
      credits: "credits",
      services: "services",
    },
    memberUsageStatus: [
      { label: "Completed", value: "Completed" },
      { label: "Upcoming", value: "BookingAccepted" },
    ],
    usageStatus: {
      completed: "Completed",
      bookingAccepted: "Booking Accepted",
    },
    cancellationPolicyType: {
      freeCancellation: "FreeCancellation",
      doNotReturnServices: "DoNotReturnServices",
    },
  },
  lifetimeRewardClub: {
    discountType: [
      { name: "Fixed Amount", value: "D" },
      { name: "%", value: "P" },
    ],
    discountTypeValue: {
      fixedAmount: "D",
      percentage: "P",
    },
  },
  twoDecimalPattern: /^\-?[0-9]+(?:\.[0-9]{1,2})?$/,
  affiliate: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
      { label: "Deleted", value: "Deleted" },
      {
        label: "Approval Pending by Admin",
        value: "ApprovalPendingbyAdmin",
      },
      { label: "Rejected by Admin", value: "RejectedbyAdmin" },
      { label: "Approval Pending by MD", value: "ApprovalPendingbyMD" },
      { label: "Rejected by MD", value: "RejectedbyMD" },
      { label: "Blocked", value: "Blocked" },
      { label: "Suspended", value: "Suspended" },
    ],
    commissionType: [
      { label: "All", value: "" },
      { label: "Business", value: "Business" },
      { label: "Facility", value: "Facility" },
      { label: "Coupon", value: "Coupon" },
      { label: "Service", value: "Service" },
      { label: "Service Provider", value: "ServiceProvider" },
      { label: "Whitelabel", value: "Whitelabel" },
      { label: "Sub Affiliate", value: "SubAffiliate" },
    ],
    AffiliateCommissionTypes: [
      { label: "All", value: "" },
      { label: "Business", value: "Business" },
      { label: "Coupon", value: "Coupon" },
    ],
    commissionValueType: [
      { name: "Fixed Amount", value: "D" },
      { name: "%", value: "P" },
    ],
    commissionValueTypeForBusiness: [
      { name: "Fixed Amount", value: "D" },
      { name: "% Net", value: "N" },
      { name: "% Gross", value: "G" },
      { name: "% Medication Selling Price", value: "S" },
    ],
    commissionValueTypeForCoupon: [
      { name: "Fixed Amount", value: "D" },
      { name: "% Gross", value: "G" },
      { name: "% Medication Selling Price", value: "S" },
    ],
    objCommissionValueType: {
      fixedAmount: "D",
      percentage: "P",
    },
    objCommissionValueTypeForBusiness: {
      fixedAmount: "D",
      percentageNet: "N",
      percentageGross: "G",
    },
    objCommissionType: {
      serviceProvider: "ServiceProvider",
      coupon: "Coupon",
      service: "Service",
      facility: "Facility",
      business: "Business",
      whiteLabel: "WhiteLabel",
      subAffiliate: "SubAffiliate",
      SubAffiliateService: "SubAffiliateService",
      SubAffiliateHCPCommission: "SubAffiliateHcp",
    },
    affiliateCommissionType: {
      serviceProvider: "ServiceProvider",
      coupon: "Coupon",
      service: "Service",
      facility: "Facility",
      business: "Business",
      whiteLabel: "Whitelabel",
      subAffiliate: "SubAffiliate",
      SubAffiliateService: "SubAffiliateService",
      SubAffiliateHCPCommission: "SubAffiliateHcp",
    },
  },
  stepCount: "0.01",
  featureCodeList: {
    FC_ServiceConsult: "FC_ServiceConsult",
    FC_Chat: "FC_Chat",
    FC_MyndshftInsurance: "FC_MyndshftInsurance",
    FC_FacilityBooking: "FC_FacilityBooking",
    FC_OnlineSupport: "FC_OnlineSupport",
    FC_TextMessageViaTwilio: "FC_TextMessageViaTwilio",
    FC_MedicalConsultDosespot: "FC_MedicalConsultDosespot",
  },
  servicePackForm: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    allowedUserType: ["Client", "Service Provider"],
    servicePackFormType: {
      medicalForms: "MedicalForm",
      procedures: "Procedures",
      informationSheets: "InformationSheets",
    },
  },
  event: {
    eventListingImage: "EventListingImage",
    eventBannerImage: "EventBannerImage",
    status: [
      { label: "Upcoming", value: "Upcoming" },
      { label: "In Progress", value: "InProgress" },
      { label: "Completed", value: "Completed" },
    ],
  },
  location: {
    locationListingImage: "LocationListingImage",
    locationBannerImage: "LocationBannerImage",
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
  },
  giftCard: {
    status: [
      { label: "Unused", value: "Unused" },
      { label: "Partially Used", value: "PartiallyUsed" },
      { label: "Fully Used", value: "FullyUsed" },
    ],
  },
  spotlight: {
    spotlightBannerImage: "spotlightBannerImage",
    status: [
      { label: "Active", value: "Active" },
      { label: "Publish", value: "Publish" },
      { label: "UnPublish", value: "UnPublish" },
    ],
    spotlightStatus: {
      Active: "Active",
      Publish: "Publish",
      UnPublish: "UnPublish",
    },
  },
  news: {
    newsBannerImage: "newsBannerImage",
    status: [
      { label: "Active", value: "Active" },
      { label: "Publish", value: "Publish" },
      { label: "UnPublish", value: "UnPublish" },
    ],
    newsStatus: {
      Active: "Active",
      Publish: "Publish",
      UnPublish: "UnPublish",
    },
  },
  condition: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
    ],
    type: [
      { label: "Treated", value: "Treated" },
      { label: "Not treated", value: "NotTreated" },
    ],
  },
  payout: {
    status: [
      { label: "Pending", value: "Pending" },
      { label: "Paid", value: "Paid" },
    ],
    payoutStatus: {
      pending: "Pending",
      paid: "Paid",
    },
    payeeType: [
      { label: "All", value: "" },
      { label: "Affiliate", value: "Affiliate" },
      { label: "Business HCP", value: "Business HCP" },
      { label: "Business", value: "Business" },
      { label: "Service Provider", value: "Service Provider" },
      { label: "Standalone HCP", value: "Standalone HCP" },
    ],
    payoutCSVColumnName:
      "CommissionPayoutTransactionId,CreatedOn,PayeeReferenceType,PayeeName,PayeeEmail,Amount,PayoutStatus,StatusChangedOn",
  },
  tipsGratuity: {
    bookingType: [
      {
        label: "Mobile/Callout Booking",
        value: "CallOut",
        name: "isCalloutBooking",
      },
      {
        label: "Facility Booking",
        value: "Facility",
        name: "isFacilityBooking",
      },
    ],
    tipsGratuityBookingType: {
      CallOut: "CallOut",
      Facility: "Facility",
      MedicalConsults: "MedicalConsults",
    },
    tipsGratuityCSVColumnName:
      "OrderNo,DatePacked,BusinessName,ServiceProvider,Client,Amount",
  },
  lifetimeRewardClubUsage: {
    status: [
      { label: "Upcoming", value: "BookingAccepted" },
      { label: "In Session", value: "SessionInProgress" },
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "BookingCancelled" },
    ],
    type: [
      { label: "Callout", value: "Callout" },
      { label: "Facility", value: "Facility" },
      { label: "On The Spot", value: "OnTheSpot" },
    ],
    displayStatus: {
      BookingAccepted: "Upcoming",
      SessionInProgress: "In Session",
      Completed: "Completed",
      BookingCancelled: "Cancelled",
    },
  },
  serviceSalesReport: {
    status: [
      { label: "Booking Accepted", value: "BookingAccepted" },
      { label: "Session In Progress", value: "SessionInProgress" },
      { label: "Completed", value: "Completed" },
      { label: "Booking Cancelled", value: "BookingCancelled" },
    ],
    headerKeyMap: {
      "orderNo": "BookingId",
      "datePacked": "ScheduleUTCDateTime",
      "orderStatus": "BookingStatus",
      "business": "BusinessName",
      "customerName": "CustomerName",
      "customerContact": "CustomerPhone",
      "customerEmail": "CustomerEmail",
      "serviceProvider": "ServiceProviderName",
      "serviceProviderCommission": "ServiceProviderCommission",
      "serviceProviderContact": "ServiceProviderPhone",
      "serviceProviderEmail": "ServiceProviderEmail",
      "healthCareProfessional": "HealthCareProfessionalName",
      "healthCareProfessionalContact": "HealthCareProfessionalPhone",
      "healthCareProfessionalEmail": "HealthCareProfessionalEmail",
      "noOfServices": "NoOfServices",
      "services": "Services",
      "serviceCost": "ServiceCost",
      "bookingFee": "BookingFee",
      "travelFee": "TravelFee",
      "tips": "TotalTips",
      "inventoryCost": "InventoryCost",
      "insurance": "Insurance",
      "insuranceCover": "InsuranceCover",
      "rewardClubDiscount": "RewardClubDiscount",
      "couponDiscount": "CouponDiscount",
      "groupDiscount": "GroupDiscount",
      "totalCharge": "TotalPayment",
      "cashPayment": "TotalCashPayment",
      "cardType": "DisplayCCTypes",
      "cardPayment": "TotalCCPayment",
      "creditPayment": "TotalCreditPayment",
      "netProfit": "NetProfit",
      "serviceProviderTravelFee": "ServiceProviderTravelFee",
      "healthCareProfessionalCommission": "HealthCareProfessionalCommission",
      "affiliateCommission": "AffiliateCommission",
      "businessCommission": "BusinessCommission",
      "whiteLabelCommission": "WhitelabelCommission",
      "calculatedBookingFee": "CalculatedBookingFee",
      "processorCharge": "ProcessorCharge",
      "processorFixCharge": "ProcessorFixCharge",
      "paymentProcessor": "PaymentProcessor",
      "bookingType": "BookingType",
      "isFreeIV": "FreeIV",
      "payment": "CancelledPaymentType",
      "clubReadyBookingId": "ClubReadyBookingId",
      "testOrder": "TestOrder"
    }
  },
  salesReport: {
    status: [
      { label: "Upcoming", value: "Upcoming" },
      { label: "In Session", value: "Session In Progress" },
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "Booking Cancelled" },
      { label: "Pending", value: "Pending" },
      { label: "InComplete", value: "InComplete" },
    ],
    netProfitBookingType: {
      callOutBooking: "- Mobile Booking",
      facilityBooking: "- Facility Booking",
      onTheSpotBooking: "- On The Spot Booking"
    },
    bookingType: [
      { label: "Mobile/Callout Booking", value: "CallOut Booking" },
      { label: "Facility Booking", value: "Facility Booking" },
      { label: "Medical Consults", value: "Medical Consult" },
      { label: "Club Ready Booking", value: "club ready" },
      // { label: "Patient Specific Booking", value: "Patient Specific Booking" }, PSF hiding
    ],
    paymentMode: [
      { label: "Credit Card", value: "CreditCard" },
      { label: "Insurance", value: "Insurance" },
      { label: "Membership", value: "Membership" },
      { label: "Gift card ", value: "Gift card" },
      { label: "Cash ", value: "Cash" },
    ],
    discount: [
      { label: "Group", value: "Group" },
      { label: "Coupon", value: "Coupon" },
      { label: "Reward Club", value: "Reward Club" },
    ],
    bookingFrom: [
      { label: "Widget", value: "Widget" },
      { label: "Application", value: "Application" },
      { label: "MobileMedIV", value: "Mobile Med Iv" },
    ],
    bookingTypeConst: {
      CallOutBooking: 'CallOut Booking',
      FacilityBooking: 'Facility Booking',
      MedicalConsult: 'Medical Consult',
      OnTheSpot: 'OnTheSpot',
      ClubReady: 'club ready'
    },
    bookingFromConst: {
      Widget: 'Widget',
      Application: 'Application',
      MobileMedIV: 'Mobile Med Iv'
    },
    salesCSVColumnName:
      "OrderNo,DatePacked,OrderStatus,Business,BusinessState,CustomerName,CustomerContact,CustomerEmail,ServiceProvider,ServiceProviderCommission,ServiceProviderContact,ServiceProviderEmail,HealthCareProfessional,HealthCareProfessionalContact,HealthCareProfessionalEmail,Payment,IsFreeIV,NoOfServices,Services,ServiceCost,BookingFee,TravelFee,Tips,InventoryCost,Insurance,InsuranceCover,RewardClubDiscount,CouponDiscount,GroupDiscount,TotalCharge,CashPayment,CardType,CardPayment,CreditPayment,NetProfit,ServiceProviderTravelFee,HealthCareProfessionalCommission,AffiliateCommission,BusinessCommission,WhiteLabelCommission,CalculatedBookingFee,ProcessorCharge,ProcessorFixCharge,PaymentProcessor,BookingType,BookingFrom",
    psfSalesCSVColumnName:
      "OrderNo,SessionId,DatePacked,UserServiceConsultRequestStatusDisplay,Services,Business,CustomerName,CustomerEmail,CustomerContact,BookingFee,MedicationCost,ShippingCost,ServiceFee,CouponDiscount,TotalCharge,ReassessmentDate,MedicationDeliveredOn,TrackingLink,PackageMonth,ReassessmentBookingId",
    affiliateCSVColumnName:"FirstName,LastName,Email,UserBookingId,AffiliateCommissionType,ServiceName,BookingDate,TotalBookingAmount,CommissionEarnedByAffiliate,BusinessName,OptedSelfMedicationRenewal,MedicationDeliveredOn,ReassessmentDate,IsReassessmentDone,ReassessmentBookingId,MedicationTrackingLink,CouponName,CouponCode,DiscountType,DiscountValue,CommissionType,CommissionValue",
    displayStatus: {
      Upcoming: "Upcoming",
      SessionInProgress: " InSession",
      Completed: "Completed",
      BookingCancelled: "Cancelled",
      Pending: "Pending",
      Incomplete: "Incomplete"
    },
    sessionDisplayStatus: {
      Pending: "Session Pending",
      Completed: "Session Completed",
      CheckinApproved:"Checkin Approved",
      CheckinInProgress:"Checkin In Progress",
      CheckinCancelled:"Checkin Cancelled",
      CheckinDenied:"Checkin Denied",
      NotAvailable: "Session Not Available",
      Lapsed: "Session Lapsed",
      Rejected: "Session Rejected",
      Incomplete: "Session Incomplete",
      Scheduled: "Session Scheduled",
      Cancelled: "Session Cancelled",
      InProgress: "Session InProgress",
      LabBooked: "Lab Booked",
      LabReceived: "Lab Received",
      LabInProgress: "Lab in Progress",
      LabCompleted: "Lab Completed",
      AwaitingConsultation: "Awaiting for Consultation",
      Consultation: "Consultation",
      SelfAdminProgramUpcoming: "Self Admin Program",
      LabCancelled: "Lab Cancelled",
      ConsultationComplete: "Consultation Complete", // ConsultationComplete for no meds psf-flow
      CspSessionBooked: "CSP Session Booked",
      CspSessionInProgress: "CSP Session In Progress",
      CspSessionCompleted: "CSP Session Completed"
    },
    viewStatus: {
      Upcoming: "Upcoming",
      SessionInProgress: "Session In Progress",
      Completed: "Completed",
      BookingCancelled: "Booking Cancelled",
      Pending: "Pending",
      Incomplete: "Incomplete",
      Cancelled: "Cancelled",
      CheckinApproved:"Checkin Approved",
    },
    cancellationPolicy: {
      noCharge: "No Charge",
    },
    displayBookingType: {
      facility: "Facility",
      callout: "Callout",
      medicalConsult: "Medical Consult",
    },
    commissionType: {
      serviceProvider: "Service Provider",
      business: "Business",
      telemedicine: "Telemedicine",
      whitelabel: "Whitelabel",
      standaloneHCP: "Standalone HCP",
      businessHCP: "Business HCP",
      affiliate: "Affiliate",
    },
    cancelledRole: {
      serviceProvider: "Service Provider",
      client: "Client",
      admin: "Admin",
      receptionist: "Receptionist",
    },
    cancelledType: {
      businessConfigureCharge: "Business Configured Charge",
      insideCancellationPolicyHour: "Inside Cancellation Policy Hour",
      outsideCancellationPolicyHour: "Outside Cancellation Policy Hour",
      noCharge: "No Charge",
    },
    orderType: {
      serviceBooking: "Service Booking",
      medicalConsult: "Medical Consult",
    },
    paymentType: {
      creditCard: "Credit Card",
      giftCard: "Gift Card",
      cash: "Cash",
      insurance: "Insurance",
      membership: "Membership",
      rewardClub: "Reward Club",
    },
    refundStatus: {
      requested: "Requested",
      deleted: "Deleted",
      approved: "Approved",
      rejected: "Rejected",
    },
    membershipDiscountType: {
      credit: "credit",
      service: "service",
      serviceOnDiscount: 'ServiceOnDiscount',
      creditOnDiscount: 'CreditOnDiscount',
    },
    discountTypeData: {
      Credit: "MembershipCredit",
      Service: "MembershipService",
      Coupon: "SpecificTypePromoCouponDiscount",
      GroupDiscount: "GroupDiscount",
      RewardClubDiscount: "RewardClubDiscount",
      AllTypePromoCouponDiscount: "AllTypePromoCouponDiscount",
      MembershipCreditOnRemaining: "MembershipCreditOnRemaining",
      MembershipServiceOnRemaining: "MembershipServiceOnRemaining",
      ReferralCodeDiscount: "ReferralCodeDiscount",
      ReferralCouponDiscount: "ReferralCouponDiscount",
      ConsultationCost: "ConsultationCost",
    },
    bookingStatus: [
      { label: "Lab Received", value: 19 },
      { label: "Lab In Progress", value: 20 },
      { label: "Checkin In Progress", value: 24 },
    ]
  },
  serilog: {
    serilogBannerImage: "serilogBannerImage",
    levels: [
      { label: "Information", value: "Information" },
      { label: "Warning", value: "Warning" },
      { label: "Error", value: "Error" },
    ],
    logs: [
      { label: "Server Logs", value: "BackOffice" },
      { label: "Mobile Logs", value: "MobileLog" },
    ],
    serviceLog: [
      { label: "Grpc Service Log", value: "GrpcServiceLogs" },
      { label: "Api Log", value: "ApiLogs" },
    ],
    serilogLevels: {
      Information: "Information",
      Warning: "Warning",
      Error: "Error",
    },
    backOffice: "BackOffice",
    serviceLogs: {
      grpcServiceLogs: "GrpcServiceLogs",
      apiLogs: "ApiLogs",
    },
  },
  mobileLogUI: {
    mobileLogUIBannerImage: "mobileLogUIBannerImage",
    mobileOSTypes: [
      { label: "Android", value: "Android" },
      { label: "IOS", value: "iOS" },
    ],
    mobileLogOSTypes: {
      Information: "Android",
      Warning: "iOS",
    },
    mobileLog: "MobileLog",
  },
  insurance: {
    salesReportCSVColumnName:
      "OrderNo,DatePacked,InsuranceStatus,Business,ServiceProvider,Amount",
    orderStatus: {
      closed: "Closed",
      open: "Open",
    },
  },
  consultation: {
    status: [
      { label: "Upcoming", value: "Scheduled" },
      { label: "InComplete", value: "InComplete" },
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "Cancelled" },
      { label: "Lapsed", value: "Lapsed" },
      { label: "Pending", value: "Pending" },
      { label: "Checkin In Progress", value: "CheckinInProgress" },
      { label: "Checkin Approved", value: "CheckinApproved" },
      { label: "Checkin Denied", value: "CheckinDenied" },
      { label: "Checkin Cancelled", value: "CheckinCancelled" },
      { label: "Pending", value: "Pending" },
    ],
    consultManagementStatus: [
      { label: "Waiting for client", value: "WaitingForClient" },
      { label: "Waiting for HCP", value: "WaitingForHcp" },
      { label: "Upcoming", value: "Upcoming" },
    ],
    statusKeys: {
      PENDING: "Pending",
    },
    DBStatus: {
      cancelled: "Booking Cancelled",
      upcoming: "Booking Accepted",
    },
    consultationType: {
      serviceConsult: "Service",
      patientSpecific: "Patient Specific",
      inSessionConsult: "In Session",
      medicalConsult: "Medical",
      medicalScreening: "Medical Screening",
    },
    requestedBy: {
      serviceProvider: "ServiceProvider"
    },
    consultationCSVColumnName: "DisplayConsultationStatus,ConsultationRequestId,UserBookingId,ClientName,ClientEmail,HCPName,HCPEmail,ScheduleDateTimeInUTC,TimeZoneName,BusinessName,HCPActualCommissionValueAmount,HCPCommissionLevelName,HCPCommissionValueAmount,ConsultationTypeDisplayValue,ClientState,BusinessState",
    consultationCSVColumnNameForAdmin: "DisplayConsultationStatus,ConsultationRequestId,UserBookingId,ClientName,ClientEmail,HCPName,HCPEmail,ScheduleDateTimeInUTC,TimeZoneName,HCPActualCommissionValueAmount,HCPCommissionLevelName,HCPCommissionValueAmount,ConsultationTypeDisplayValue,ClientState,BusinessState,BusinessName",
    checkinCSVColumnName: "DisplayConsultationStatus,ConsultationRequestId,UserBookingId,ClientName,ClientEmail,HCPName,HCPEmail,ScheduleDateTimeInUTC,TimeZoneName,HCPActualCommissionValueAmount,HCPCommissionLevelName,HCPCommissionValueAmount,ConsultationTypeDisplayValue,ClientState,BusinessState",
    checkinCSVColumnNameForAdmin: "DisplayConsultationStatus,ConsultationRequestId,UserBookingId,ClientName,ClientEmail,HCPName,HCPEmail,ScheduleDateTimeInUTC,TimeZoneName,HCPActualCommissionValueAmount,HCPCommissionLevelName,HCPCommissionValueAmount,ConsultationTypeDisplayValue,ClientState,BusinessState,BusinessName",
    consultType: [
      { label: "Service Consult", value: "Service Consult" },
      { label: "InSession Consult", value: "InSession Consult" },
      { label: "Patient Specific Consult", value: "Patient Specific Consult" },
      { label: "Medical Screening", value: "Medical Screening" },
      {label: "Async Consult", value: "Async"}
    ],
    displayStatus: {
      medicalScreeningCompleted: "Completed",
      medicalScreeningCancelled: "Cancelled",
    },
    consultationWaiting: {
      consultationType: {
        InSession: "InSession"
      }
    },
    bookingType: [{ label: "Business", value: "Business" },
    { label: "ClubReady", value: "Club Ready" }]
  },
  consultationWaiting: {
    moduleName: "ConsultationWaiting",
    waitingStatus: [
      { label: "0-5", value: "0-5" },
      { label: "5-10", value: "5-10" },
      { label: "10-15", value: "10-15" },
      { label: "15+", value: "15+" }
    ],
    status: [
      { label: "Upcoming", value: "Scheduled" },
      { label: "InComplete", value: "InComplete" },
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "Cancelled" },
      { label: "Lapsed", value: "Lapsed" }
    ],
    titleType: {
      title1: "Waiting view",
      title2: "Waiting",
    },
  },
  dashboard: {
    status: {
      active: "Active",
    },
  },
  inventory: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Inactive", value: "InActive" },
      { label: "Deleted", value: "Deleted" },
    ],
    referenceType: {
      serviceProvider: "Service Provider",
    },
    adjustment: {
      adding: "Adding",
      deducting: "Deducting",
    },
    inventoryStatus: {
      deleted: "Deleted",
    },
  },
  inventoryOrder: {
    status: [
      { label: "Ordered", value: "Ordered" },
      { label: "Shipped", value: "Shipped" },
      { label: "Delivered", value: "Delivered" },
    ],
    orderStatus: {
      Ordered: "Ordered",
      Shipped: "Shipped",
      Delivered: "Delivered",
      PartiallyDelivered: "Partially Delivered",
      Deleted: "Deleted",
    },
  },
  payoutAdjustments: {
    status: {
      completed: "Completed",
      pending: "Pending",
      active: "Active",
      deleted: "Deleted",
    },
    adjustmentType: [
      { label: "Positive", value: 1 },
      { label: "Negative", value: -1 },
    ],
    userType: [
      { label: "Service provider", value: "Service Provider" },
      { label: "Business HCP", value: "Business HCP" },
      { label: "Standalone HCP", value: "Standalone HCP" },
      { label: "Affiliate", value: "Affiliate" },
      { label: "Business", value: "Business" },
    ],
    type: [
      { label: "Service Booking", value: "Service Booking" },
      { label: "Medical Consultation", value: "Medical Consultation" },
      { label: "Service Consultation", value: "Service Consultation" },
    ],
    userTypeValue: {
      business: "Business",
      serviceProvider: "Service Provider",
      standaloneHCP: "Standalone HCP",
      businessHCP: "Business HCP",
      affiliate: "Affiliate",
    },
    bookingType: {
      serviceBooking: "Service Booking",
      medicalConsultation: "Medical Consultation",
      serviceConsultation: "Service Consultation",
    },
    adjustmentTypeValue: {
      positive: "Positive",
      negative: "Negative",
    },
  },
  inventoryUsed: {
    status: [
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "Booking Cancelled" },
    ],
    displayStatus: {
      Completed: "Completed",
      BookingCancelled: "Cancelled",
    },
  },
  commissionBalance: {
    bookingType: {
      serviceBooking: "Service Booking",
      medicalConsultation: "Medical Consultation",
    },
  },
  paymentConfigurations: {
    status: [
      { label: "Active", value: "Active" },
      { label: "In Active", value: "InActive" },
    ],
    apiMode: [
      { label: "Sandbox", value: "Sandbox" },
      { label: "Production", value: "Production" },
    ],
  },
  businessPaymentConfigurations: {
    status: [
      { label: "Active", value: "Active" },
      { label: "Deleted", value: "Deleted" },
    ],
  },
  complimentaryIVPage: {
    displayStatus: {
      BookingAccepted: "Upcoming",
      SessionInProgress: "In Session",
      Completed: "Completed",
      BookingCancelled: "Cancelled",
    },
    status: [
      { label: "Upcoming", value: "BookingAccepted" },
      { label: "In Session", value: "SessionInProgress" },
      { label: "Completed", value: "Completed" },
      { label: "Cancelled", value: "BookingCancelled" },
    ],
    type: [
      { label: "Callout", value: "Callout" },
      { label: "Facility", value: "Facility" },
      { label: "On The Spot", value: "OnTheSpot" },
    ],
  },
  trainingSubject: {
    allowedUserType: [
      "Client",
      "Service Provider",
      "Admin",
      "Receptionist",
      "Affiliate",
      "MD",
      "HCP",
    ],
    status: [
      { label: "UnPublished", value: "UnPublished" },
      { label: "Published", value: "Published" },
    ],
    topicStatus: [
      { label: "Draft", value: "Draft" },
      { label: "Pending Review", value: "PendingReview" },
      { label: "Finished", value: "Finished" },
    ],
    allowedRole: ["Admin", "MD", "Client", "Service Provider", "HCP", "Receptionist", "Affiliate"],
    subjectStatus: [
      { label: "Published", value: "Published" },
      { label: "UnPublished", value: "UnPublished" },
    ],
    answerType: [
      { label: "Single Choice Question", value: "Single Choice Question" },
      { label: "Multiple Choice Question", value: "Multiple Choice Question" },
    ],
    answerTypes: {
      singleChoiceQuestion: "Single Choice Question",
      multipleChoiceQuestion: "Multiple Choice Question",
    },
    displayStatus: {
      Published: "Published",
      UnPublished: "UnPublished",
    },
  },
  trainingUser: {
    allowedRole: ["Master Admin", "Admin", "MD", "Client", "Insurance Partner", "Pharmacy"],
  },
  trainingResult: [
    { label: "Correct", value: true },
    { label: "Incorrect", value: false },
  ],

  clientTrainingResult: {
    progressStatus: [{
      label: "Completed",
      value: "Completed",
    }],
  },

  whiteLabelNames: {
    telemedicine: { name: "Telemedicine" },
    dripbar: { name: "THE DRIPBaR" },
    hydreight: { name: "Hydreight" },
    milTeste: { name: "MilTestes" },
    metaVet: { name: "metavet" },
    vsdigital: { name: "VSDigital" },
  },

  notification: {
    status: [
      { label: "Sent", value: "Sent" },
      { label: "Pending", value: "Pending" },
    ],

    role: [
      { label: "Business", value: "Business" },
      { label: "Service Provider", value: "Service Provider" },
      { label: "Client", value: "Client" },
    ],
    recipientType: {
      Business: "Business",
      ServiceProvider: "Service Provider",
      Client: "Client",
    },
  },
  browser: {
    chrome: "chrome",
    firefox: "firefox",
    safari: "safari",
    opera: "opera",
    edge: "edge"
  },
  browserPlatform: {
    MacIntel: "MacIntel",
    MacPPC: "MacPPC",
    Mac68K: "Mac68K",
    Win32: "Win32",
    Win16: "Win16"
  },
  homeSetupSections: {
    Consultations: 'Consultations',
    ConsultationRequired: 'Consultation Required',
    Messages: 'Messages'
  },
  emailTemplate: {
    notificationTags: {
      logoPath: 'logoPath',
      TermsAndConditionsURL: 'TermsAndConditionsURL',
      CustomerSupportEmail: 'CustomerSupportEmail',
      AppStoreLink: 'App_Store_Link',
      GoogleplayLink: 'Google_play_Link',
    },
    defaultElements: {
      logoPath: '<img src="{{logoPath}}" style="text-align: center;width:12.06% ">',
      TermsAndConditions: '<a href="{{TermsAndConditionsURL}}">Terms and Conditions</a>',
      CustomerSupportEmail: '<a href="mailto:{{customerSupportEmail}}?subject=Enquiry from Email Receipt!">{{CustomerSupportEmail}}.</a>',
      AppStoreLink: '<a href="{{App_Store_Link}}"><img src="{{App_Store_Badge}}" style="width:150px"></a>',
      GoogleplayLink: '<a href="{{Google_play_Link}}"><img src="{{Google_play_badge}}" style="width:150px"></a>',
    },
    templateStatus: {
      Publish: 1,
      Draft: 2
    },
    templateType: {
      Email: 'Email'
    },
    templateCategoryType: {
      Email: 1,
      SMS: 2,
      PushNotification: 3
    },
    templateStyles: {
      headerFontSize: '24px',
      fontFamily: 'Helvetica,Arial,sans-serif',
      fontWeight: 'normal',
      borderCollapse: 'collapse',
      fontSize: '14px',
      tableWidth: '100%'
    },
    TemplateTags: {
      Whitelabel: 'Whitelabel'
    }
  },
  clubReadyBookingSyncLog: {
    status: [
      { label: "Discarded", value: "Discarded" },
      { label: "Pending", value: "Pending" },
      { label: "Synced", value: "Synced" },
      { label: "Cancelled", value: "Cancelled" },
      { label: "Reschedule", value: "Reschedule" },
    ],
    displayStatus: {
      Discarded: "Discarded",
      Pending: "Pending",
      Synced: "Synced",
      Cancelled: "Cancelled",
      Reschedule: "Reschedule"
    },
  },
  subscriptionReport: {
    userType: [
      "Admin",
      "Client",
      "Service Provider",
      "HCP",
      "Standalone HCP",
      "Pharmacy"
    ],
  },
  paperWork: {
    acceptTermsAndConditionsPdf: "AcceptTermsAndConditionsPdf"
  },
  ClientReport: {
    clientReportOrderType: [
      { value: 'Top Sales', label: 'Top Sales($)' },
      { value: 'Top 10 Sales', label: 'Top 10 Sales($)' },
      { value: 'Top 20 Sales', label: 'Top 20 Sales($)' },
      { value: 'Top Sales Order', label: 'Top Sales(No of orders)' },
      { value: 'Top 10 Sales Order', label: 'Top 10 Sales(No of orders)' },
      { value: 'Top 20 Sales Order', label: 'Top 20 Sales(No of orders)' },
      { value: 'Most Discounted', label: 'Most Discounted' },
    ],
    clientRating: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
    ],
    clientReportVitalFilter: [
      { value: 'Blood Pressure', label: 'Blood Pressure' },
      { value: 'BPM', label: 'BPM' },
      { value: 'Temperature', label: 'Temperature' },
      { value: 'Respiration Rate', label: 'Respiration Rate' },
      { value: 'Lung Sounds', label: 'Lung Sounds' },
      { value: 'SpO2', label: 'SpO2' },
    ]
  },
  Telemedicine: {
    MedicalScreeningSetting: {
      CommissionLevelList: [
        { label: "Level 1", value: "Level 1" },
        { label: "Level 2", value: "Level 2" },
        { label: "Level 3", value: "Level 3" },
        { label: "Level 4", value: "Level 4" },
      ],
      commonStatus: [
        { label: "Active", value: 1 },
        { label: "Inactive", value: 2 },
      ],
      validPeriod: [
        { label: "Weeks", value: "Weeks" },
        { label: "Months", value: "Months" },
        { label: "Years", value: "Years" },
      ]
    }
  },
  TelemedicineTypes: {
    ServiceConsult: 'ServiceConsult',
    PatientSpecificService: 'PatientSpecificService'
  },
  exportCSV: {
    page: 1,
    itemsPerPage: 1000000
  },
  complimentaryIV: {
    Status: [
      { label: "Inactive", value: "InActive" },
      { label: "Active", value: "Active" },
    ],
  },
  mobileMedIVServiceStatusList: [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "InActive" },
  ],
  Genders: {
    M: "Male",
    F: "Female"
  },
  PharmacyOrderTypes: {
    openOrder: 1,
    partialOrder: 2,
    ordered: 3,
    delivered: 4,
  },
  pharmacyOrdersCSVColumnName:
  "UserServiceConsultPharmacyOrderId,UserBookingId,MedicationOrderedOn,ClientName,Email,Phone,ClientAddress,City,StateName,Zipcode,Gender,BusinessName,MedicationName",
  pharmacyAuditLogCSVColumnName:
    "UserServiceConsultPharmacyOrderId,UserBookingId,TrackingId,TrackingLink",

  consultationInWaiting: {
    consultationInWaitingDashboardBlock: {
      stage1: "#FDBF02",
      stage2: "#FE7E03",
      stage3: "#FF0507",
      stage0: "#47CF4F",
    }
  },
  preAssessment: {
    moduleName: "Pre Assessment",
    statusList: [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ],
  },
  clientReportType: {
    prescriptionDetails: "prescriptionDetails",
    soapNotes: "soapNotes"
  },
  psfSessionBookingStatus :{
    PENDING: 0,
    SCHEDULED: 1,
    INCOMPLETE: 2,
    COMPLETED: 3,
    CANCELLED: 4,
    NOTAVAILABLE: 5,
    LAPSED: 6,
    REJECTED: 7,
    INPROGRESS: 9,
  },
  metabaseDasboardIdentifier: {
    coverageDashboard : 7,
    consultationDrillDown : 8,
    consultationDashboard : 9,
    commissionDashboard : 10,
    servicesBookedDashboard : 14,
  },
  moduleName:{
    location: "Location",
  },
  upcomingConsults: {
    consultStatusType: [
      {
        label: "Waiting for HCP",
        value: "WaitingForHcp",
      },
      {
        label: "Waiting for Client",
        value: "WaitingForClient",
      },
      {
        label: "Pending HCP Response",
        value: "PendingHcpResponse",
      },
      {
        label: "Denied by HCP",
        value: "DeniedByHcp",
      }
    ],
    hcpResponse: [
      {
        label: "Accepted",
        value: "Accepted",
      },
      {
        label: "Denied",
        value: "DeniedByHcp",
      },
      {
        label: "Pending",
        value: "PendingHcpResponse",
      },
    ],
    hcpResponseEnum: {
      Accepted: "Accepted",
      Denied: "Denied",
      Pending: "Pending",
    },
    waitTime: [
      {
        label: "0-2 min",
        valueMin: 0,
        valueMax: 2
      },
      {
        label: "2-5 min",
        valueMin: 2,
        valueMax: 5
      },
      {
        label: "5-10 min",
        valueMin: 5,
        valueMax: 10
      },  
      {
        label: ">10 min",
        valueMin: 10,
        valueMax: 100000
      },
    ]
  },
    priceFloatingPoints: 2,
    sequenceData: {
      Credit: "Credit",
      Coupon: "Coupon",
    },
    defaultUserPic: "default_user.png"
};
